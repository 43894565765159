export default {
  gray: {
    primary: '#2C2C2C',
    second: '#414141',
    third: '#3C3C3C',
    fourth: '#B9B9B9',
    light: '#BFBFBF',
    medium: '#707070',
    hard: '#323232',
  },
  black: {
    primary: '#141414',
    primary70: 'rgba(20,20,20,0.7)',
    secondary: '#000000',
    secondary80: 'rgba(0,0,0,0.8)',
  },
  yellow: {
    primary: '#FFD222',
  },
  yellow2: {
    primary: '#DF8E16',
  },
  red: {
    primary: '#FF1A14',
  },
  white: {
    primary: '#FFFFFF',
  },
  green: {
    primary: '#09D340',
  },
  copper: {
    primary: '#DF8E16',
    primary50: '#FFE658',
  },
  gradient: {
    primary:
      'linear-gradient(89.15deg, #FFE658 -5.76%, #FFD222 30.09%, #000000 117.54%);',
    black: 'linear-gradient(90deg, #a1aed1 40%, #030303 90%);',
    purple: 'linear-gradient(194deg, #000 72.74%, #4A1590 93.93%, #8C02B0 115.11%);',
    new: 'linear-gradient(90deg, rgba(140, 2, 176, 0.90) 0%, rgba(74, 21, 144, 0.90) 38%, rgba(76, 20, 139, 0.90) 39%, rgba(102, 12, 79, 0.90) 49%, rgba(121, 6, 36, 0.90) 57%, rgba(132, 3, 9, 0.90) 64%, rgba(137, 2, 0, 0.90) 67%, rgba(236, 37, 55, 0.90) 77%, rgba(233, 60, 47, 0.90) 79%, rgba(228, 95, 36, 0.90) 82%, rgba(225, 121, 28, 0.90) 85%, rgba(223, 136, 23, 0.90) 88%, rgba(223, 142, 22, 0.90) 90%, rgba(32, 161, 127, 0.90) 100%);'
  },
}
