import React from 'react'

import NewButton from '../../components/Buttons/NewButton'

import * as S from './styles'

//Icons
import { IoClose } from "react-icons/io5";
import { IoCheckmarkCircle } from "react-icons/io5";
import { PiWarningCircleFill } from "react-icons/pi";

interface CustomModalProps {
  children: React.ReactNode
  title?: string
  center?: boolean
  type?: "default" | "error" | "success"
  onClose: () => void
  btnText?: string
  btnAction?: () => void
}

const CustomModal: React.FC<CustomModalProps> = ({
  children,
  title,
  type = "default",
  center = false,
  onClose,
  btnText,
  btnAction
}) => {


  return (
    <S.Wrapper>
      <S.Modal>
        <S.Close onClick={onClose}>
          <IoClose/>
        </S.Close>

        {type === "error" ? (
          <S.TypeIcon>
            <PiWarningCircleFill className={"error"}/>
          </S.TypeIcon>
        ) : type === "success" ? (
          <S.TypeIcon>
            <IoCheckmarkCircle className={"success"}/>
          </S.TypeIcon>
        ) : <></> }

        {title ? (
          <S.Title>{title}</S.Title>
        ) : <></>}
        <S.Body style={{textAlign: center ? "center" : "left"}}>
          {children}
        </S.Body>
        {
          btnText && btnAction ? (
            <S.Action>
              <NewButton onClick={btnAction}>{btnText}</NewButton>
            </S.Action>
          ) : <></>
        }
      </S.Modal>
    </S.Wrapper>
  )
}

export default CustomModal
