import React, {useState, useEffect} from 'react'
import {Col, Row} from 'react-grid-system'
import ReCAPTCHA from 'react-google-recaptcha'
import {Container} from '../../styles/globals'
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router'
import * as S from './styles'
import TextField from '../../components/Inputs/TextField'
import SelectField from '../../components/Inputs/SelectField'
import PasswordField from '../../components/Inputs/PasswordField'
import Realization from '../../components/Realization'

import ufList from '../../utils/ufList'

import {useAuth} from '../../context/AuthContext/Context'
import recaptchaService from '../../service/recaptcha'

import userService from '../../service/users'
import LinkButton from '../../components/Buttons/LinkButton'
import NewButton from '../../components/Buttons/NewButton'

interface IFormInputs {
  name: string
  email: string
  office: string
  state: string
  city: string
  password: string
}

const schema = yup.object({
  name: yup
    .string()
    .min(3, 'Pelo menos 3 caracteres')
    .required('Este campo é obrigatório'),
  email: yup
    .string()
    .min(5, 'Pelo menos 5 caracteres')
    .email('Digite um e-mail válido')
    .required('Este campo é obrigatório'),
  office: yup
    .string()
    .min(2, 'Pelo menos 2 caracteres')
    .required('Este campo é obrigatório'),
  state: yup.string().required('Obrigatório'),
  city: yup
    .string()
    .min(3, 'Pelo menos 3 caracteres')
    .required('Este campo é obrigatório'),
  password: yup
    .string()
    .min(8, 'Digite pelo menos 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).{8,256}$/,
      'Deve conter pelo menos 8 caracteres, um caractere especial, uma letra maiúscula e uma minúscula.',
    )
    .required('Este campo é obrigatório'),
})

const Register: React.FC = () => {
  const history = useHistory()
  return (
    <Container>
      <S.RegisterWrapper>
        <S.TxtInfo>
          Olá ;) <br />
          Preencha todos os campos para criar sua conta
        </S.TxtInfo>
        <RegisterForm />
        <S.WrapperLogin>
          <LinkButton onClick={() => history.push('/login')}>
            JÁ POSSUO UMA CONTA
          </LinkButton>
        </S.WrapperLogin>
        <Realization />
      </S.RegisterWrapper>
    </Container>
  )
}

export default Register

export const RegisterForm: React.FC = () => {
  const {login} = useAuth()

  const [loading, setLoading] = useState(false)

  const [recaptchaIsValid, setRecaptchaIsValid] = useState(false)

  const [recaptchaError, setRecaptchaError] = useState(false)

  async function onChangeCaptcha(value: string | null) {
    const {success} = await recaptchaService.validate({
      value,
    })

    if (success && success === true) {
      setRecaptchaIsValid(true)
      setRecaptchaError(false)
    } else {
      setRecaptchaIsValid(false)
      setRecaptchaError(true)
    }
  }

  useEffect(() => {
    if (recaptchaIsValid === true) {
      setRecaptchaError(false)
    }
  }, [recaptchaIsValid])

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const checkErrors = () => {
    if (
      errors &&
      Object.keys(errors).length &&
      Object.keys(errors).length !== 0
    ) {
      toast.error('Verifique os campos do formulário', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const onSubmit = async (data: IFormInputs) => {
    setLoading(true)

    if (recaptchaIsValid !== true) {
      setRecaptchaError(true)
      setLoading(false)
      return
    }

    await userService
      .postUser({
        ...data,
        role: 'Comum',
      })
      .then(() => {
        setLoading(false)
        toast.success('Sua conta foi criada!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        login(data.email, data.password, true)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.data.status === 503) {
          toast.error(error.response.data.data.text, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          return
        }
        toast.error(
          Array.isArray(error.response.data.modal)
            ? error.response.data.modal[0]
            : error.response.data.modal,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        )
      })
  }

  return (
    <S.RegisterForm onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm={12}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({field: {onChange, onBlur, value, ref}}) => (
              <S.WrapperFields first>
                <TextField
                  label="Nome"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  error={!!errors && !!errors.name}
                  errorMessage={
                    !!errors && !!errors.name ? errors.name.message : ''
                  }
                />
              </S.WrapperFields>
            )}
          />
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({field: {onChange, onBlur, value, ref}}) => (
              <S.WrapperFields>
                <TextField
                  label="E-mail"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  error={!!errors && !!errors.email}
                  errorMessage={
                    !!errors && !!errors.email ? errors.email.message : ''
                  }
                />
              </S.WrapperFields>
            )}
          />
          <Controller
            control={control}
            name="office"
            defaultValue=""
            render={({field: {onChange, onBlur, value, ref}}) => (
              <S.WrapperFields>
                <TextField
                  label="Empresa"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  error={!!errors && !!errors.office}
                  errorMessage={
                    !!errors && !!errors.office ? errors.office.message : ''
                  }
                />
              </S.WrapperFields>
            )}
          />
          <S.LimitedDiv>
            <S.WrapperFields>
              <Row>
                <Col xs={12} sm={12} md={3}>
                  <Controller
                    control={control}
                    name="state"
                    defaultValue=""
                    render={({field: {onChange, onBlur, value, ref}}) => (
                      <SelectField
                        options={['', ...ufList]}
                        label="UF"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        error={!!errors && !!errors.state}
                        errorMessage={
                          !!errors && !!errors.state ? errors.state.message : ''
                        }
                      />
                    )}
                  />
                </Col>
                <Col sm={9}>
                  <Controller
                    control={control}
                    name="city"
                    defaultValue=""
                    render={({field: {onChange, onBlur, value, ref}}) => (
                      <TextField
                        label="Cidade"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={ref}
                        autoComplete="new-password"
                        error={!!errors && !!errors.city}
                        errorMessage={
                          !!errors && !!errors.city ? errors.city.message : ''
                        }
                      />
                    )}
                  />
                </Col>
              </Row>
            </S.WrapperFields>
          </S.LimitedDiv>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            render={({field: {onChange, onBlur, value, ref}}) => (
              <S.WrapperFields>
                <PasswordField
                  label="Senha"
                  autoComplete="new-password"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  error={!!errors && !!errors.password}
                  errorMessage={
                    !!errors && !!errors.password ? errors.password.message : ''
                  }
                />
              </S.WrapperFields>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <S.WrapperRecaptcha>
            <ReCAPTCHA
              sitekey="6Lep34ohAAAAAMK_COExiyLlhIQRmTponouFpzga"
              onChange={onChangeCaptcha}
              theme="dark"
            />
            {recaptchaError && (
              <S.RecaptchaError>
                Por favor confirme que você não é um robô.
              </S.RecaptchaError>
            )}
          </S.WrapperRecaptcha>
          <S.WrapperActionButton>
            <NewButton
              disabled={loading}
              isLoading={loading}
              type="submit"
              onClick={checkErrors}>
              Criar Conta
            </NewButton>
          </S.WrapperActionButton>
        </Col>
      </Row>
    </S.RegisterForm>
  )
}
