interface TimelineItem {
  dateStart: string
  dateEnd: string
  dateText: string
  title: string
  text: string
  btnText?: string
  btnLink?: string
  external?: boolean
}

const timelineItems: TimelineItem[] = [
  // {
  //   dateStart: '2023-09-05T22:00:00.001Z',
  //   dateEnd: '2023-09-05T22:30:00.001Z',
  //   dateText: '05/09',
  //   title: 'Lançamento Redes Sociais',
  //   text: 'Lançamento nas redes sociais.',
  // },
  // {
  //   dateStart: '2023-09-11T22:00:00.001Z',
  //   dateEnd: '2023-09-11T22:30:00.001Z',
  //   dateText: '11/09',
  //   title: 'Lançamento da Plataforma',
  //   text: 'Lançamento da plataforma.',
  // },
  {
    dateStart: '2024-10-03T19:30:00.001Z',
    dateEnd: '2024-10-17T23:59:59.001Z',
    dateText: '03/10',
    title: 'Abertura das indicações da Academia',
    text: 'Lançamento da 12ª edição do Startup Awards e abertura das indicações da Academia.',
  },
  {
    dateStart: '2024-10-17T23:30:00.001Z',
    dateEnd: '2024-10-17T23:59:59.001Z',
    dateText: '17/10',
    title: 'Encerramento das indicações da Academia',
    text: 'Encerramento das indicações.',
  },
  {
    dateStart: '2024-10-28T19:30:00.001Z',
    dateEnd: '2024-11-11T23:59:59.001Z',
    dateText: '28/10',
    title: 'Anúncio do TOP 10 e abertura para votação pública',
    text: 'Início da votação aberta a todo o público. Cada indivíduo vota em uma das 10 opções de cada categoria.',
  },
  {
    dateStart: '2024-11-11T00:00:01.001Z',
    dateEnd: '2024-11-11T23:59:59.001Z',
    dateText: '11/11',
    title: 'Encerramento da votação',
    text: 'Encerramento da votação aberta a todo o público.',
  },
  // {
  //   dateStart: '2023-09-19T22:00:00.001Z',
  //   dateEnd: '2023-09-19T02:59:59.001Z',
  //   dateText: '19/09',
  //   title: 'Anúncio dos 10 finalistas',
  //   text: 'Vamos divulgar nas nossas redes sociais a lista com os 10 finalistas de cada categoria.',
  //   btnText: 'Lista 10+ indicados',
  //   btnLink: '/indicados',
  //   external: false,
  // },
  // {
  //   dateStart: '2023-09-19T22:30:00.001Z',
  //   dateEnd: '2023-10-08T02:59:59.001Z',
  //   dateText: '19/09 - 07/10',
  //   title: 'Período de votação 3 finalistas',
  //   text: 'Durante esse período, a Academia do Awards seleciona os 3 finalistas de cada categoria.',
  // },
  {
    dateStart: '2024-11-13T00:00:01.001Z',
    dateEnd: '2024-11-22T23:59:59.001Z',
    dateText: '13/11',
    title: 'Anúncio TOP3 e abertura da votação para Academia',
    text: 'Divulgação dos 3 mais votados de cada categoria. Início da votação final da Academia.',
    // btnText: 'Lista dos finalistas',
    // btnLink: '/finalistas',
    // external: false,
  },
  // {
  //   dateStart: '2023-10-31T22:00:00.001Z',
  //   dateEnd: '2023-10-31T23:59:59.001Z',
  //   dateText: '31/10',
  //   title: 'Entrega dos cabides',
  //   text: 'Entrega dos cabides.',
  // },
  {
    dateStart: '2024-11-22T23:30:00.001Z',
    dateEnd: '2024-11-22T23:59:59.001Z',
    dateText: '22/11',
    title: 'Encerramento da votação',
    text: 'Encerramento da votação final e início do processo de apuração e auditoria.',
  },
  // {
  //   dateStart: '2023-11-17T02:59:59.001Z',
  //   dateEnd: '2023-11-17T02:59:59.001Z',
  //   dateText: '17/11',
  //   title: 'Auditoria dos vencedores',
  //   text: 'Auditoria dos vencedores dos 3 finalistas de cada categoria.',
  // },
  {
    dateStart: '2024-11-29T10:00:00.001Z',
    dateEnd: '2024-11-30T02:59:59.001Z',
    dateText: '29/11',
    title: 'Premiação no último dia do CASE',
    text: 'O grande dia. Vamos nos reunir presencialmente para celebrar os vencedores e vencedoras do Startup Awards 2k24.',
    // btnText: 'Conheça os vencedores',
    // btnLink: '/vencedores',
    // external: false,
  },
]

export default timelineItems
