import React, {useState} from 'react'
import AppContext from './Context'

const AppProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <AppContext.Provider value={{isLoading, setIsLoading}}>
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
