import React from 'react'
import Routes from './routes'
import './app.css'
import Header from './components/Header'
import Menu from './components/Menu'
import {setConfiguration} from 'react-grid-system'
import AuthProvider from './context/AuthContext/Provider'

import VotationProvider from './context/VotationContext/Provider'
import {ToastContainer} from 'react-toastify'
import {BrowserRouter as Router} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {useApp} from './context/AppContext/Context'
import AppLoader from './components/AppLoader'

function App() {
  setConfiguration({maxScreenClass: 'xl'})

  const {isLoading} = useApp()

  return (
    <>
      <Router>
        <AuthProvider>
          <VotationProvider>
            <Header />
            <Menu />
            {isLoading ? <AppLoader /> : <Routes />}
          </VotationProvider>
        </AuthProvider>
        <ToastContainer />
      </Router>
    </>
  )
}

export default App
