import styled from 'styled-components'
import colors from '../../../styles/colors'

export const Button = styled.button`
  width: 100%;
  padding: 9px 24px;
  width: fit-content;
  background-color: ${colors.copper.primary};
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 14px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  color: white;

  & img {
    width: 13px;
  }
`
