import React, {InputHTMLAttributes, Ref} from 'react'
import * as S from './styles'

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  placeholder?: string
  name?: string
  error?: boolean
  errorMessage?: string
  inputRef?: Ref<HTMLInputElement> | null | undefined
}

const TextField: React.FC<TextFieldProps> = (props) => {
  return (
    <S.WrapperField>
      {props.label && <label>{props.label}</label>}
      <S.TextField {...props} ref={props.inputRef} />
      {props.error && <S.ErrorMessage>{props.errorMessage}</S.ErrorMessage>}
    </S.WrapperField>
  )
}

export default TextField
