import React, {useState, useRef} from 'react'

import * as S from './styles'

interface StyleProp {
  backgroundImage: string
  transform?: string
}

interface HistoricoSlideImageProps {
  image: string
}

const HistoricoSlideImage: React.FC<HistoricoSlideImageProps> = ({image}) => {
  const element = useRef<HTMLInputElement>(null)

  const [style, setStyle] = useState<StyleProp>({
    backgroundImage: `url(${image})`,
    transform: `perspective(1000px)`,
  })

  const handleMouseMove = (e: React.MouseEvent<HTMLInputElement>) => {
    const i = window.innerWidth

    if (element.current) {
      const s = element.current.getBoundingClientRect()

      const a = Math.round(s.left - 5)
      const r = Math.round(s.left + s.width + 5)
      const n = Math.round(s.top - 5)
      const o = Math.round(s.top + s.height + 5)
      const l = Math.round(s.left + s.width / 2)
      const d = Math.round(s.top + s.height / 2)
      const h = l - e.clientX
      const p = d - e.clientY
      const c = (3.5 / s.width) * i
      const u = (3 / s.width) * i

      let v = 0
      let f = 0

      e.clientX > a &&
        e.clientX < r &&
        e.clientY > n &&
        e.clientY < o &&
        ((v = (c / (s.width / 2)) * p), (f = (u / (s.height / 2)) * h * -1)),
        v > c && (v = c),
        v < -1 * c && (v = -1 * c),
        f > u && (f = u),
        f < -1 * u && (f = -1 * u)

      const updated =
        'perspective(1000px) rotateX(' +
        v +
        'deg) rotateY(' +
        f +
        'deg) translateZ(0)'

      setStyle({
        ...style,
        transform: updated,
      })
    }
  }

  const handleMouseOut = () => {
    setStyle({
      ...style,
      transform: 'perspective(1000px) scale(1) rotateX(0) rotateY(0)',
    })
  }

  return (
    <S.SlideImage
      ref={element}
      style={style}
      onMouseMove={handleMouseMove}
      onMouseOut={handleMouseOut}
    />
  )
}

export default HistoricoSlideImage
