import styled from 'styled-components'
import colors from '../../styles/colors'

export const WrapperRealization = styled.div`
  position: relative;
  margin: 60px auto 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.p`
  display: inline-block;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 115%;
  color: ${colors.gray.light};
  text-transform: uppercase;
  margin: 0;
  padding: 0;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`
