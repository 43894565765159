import styled from 'styled-components'
import ActionButton from '../../components/Buttons/ActionButton'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import colors from '../../styles/colors'

interface ListWrapperProps {
  transparent: boolean
}

interface OverlayConfirmVoteProps {
  show: boolean
}

interface ListItemProps {
  voted: boolean
  limitAccess: boolean
}

export const WrapperRealization = styled.div`
  margin: 30px auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`

export const WrapperGiveBack = styled.div`
  text-align: center;
  margin: 40px 0px 77px;
  // Remover abaixo ao voltar patrocinador
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  margin: 0 0 30px;

  p {
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: ${colors.gray.light};
  }

  img {
    height: 35px;
    width: auto;
  }
`

export const TitleWrapper = styled.div`
  margin-top: -10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled.h1`
  letter-spacing: -0.03em;
  line-height: 1.1;
  color: ${colors.white.primary};
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  display: block;
  margin-bottom: 20px;
`

export const SubTitleWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  button {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  & > div {
    width: 90%;
  }
`

export const CriterionButton = styled.button`
  max-width: 301px;
  width: 100%;
  height: 38px;
  background: ${colors.gray.primary} 0% 0% no-repeat padding-box;
  border: 1px solid ${colors.black.primary};
  border-radius: 10px;
  font-size: 11px;
  letter-spacing: 3.3px;
  color: ${colors.gray.light};
  cursor: pointer;

  &:hover {
    background: ${colors.gray.second} 0% 0% no-repeat padding-box;
  }
`

export const ListWrapper = styled.div<ListWrapperProps>`
  margin-top: 35px;
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: ${({transparent}) => (transparent ? '0' : '1')};
  transition: 0.2s;

  & > button {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  & > p{
    display: block;
    width: 100%;
    text-align: center;
    line-height: 135%;
    color: ${colors.white.primary}
  }
`

export const ListItem = styled.button<ListItemProps>`
  width: 100%;
  height: 38px;
  position: relative;
  overflow: hidden;
  background: ${colors.gray.second} 0% 0% no-repeat padding-box;
  border-radius: 8px;
  cursor: pointer;
  border: ${({voted}) =>
    voted ? `1px solid ${colors.green.primary}` : 'none'};
  font-size: 12px;
  letter-spacing: 1.8px;
  color: ${({voted}) => (voted ? colors.green.primary : colors.gray.light)};
  text-transform: uppercase;
  padding 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events ${({limitAccess}) => (limitAccess ? 'none' : 'all')};

  & > img{
    display: ${({limitAccess}) => (limitAccess ? 'none' : 'block')};
  }
`

export const OverlayConfirmVote = styled.div<OverlayConfirmVoteProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.green.primary};
  opacity: ${({show}) => (show ? '1' : '0')};
  transition: 0.3s;

  & > span {
    color: ${colors.black.primary};
    font-size: 12px;
  }
`

export const PaginationWrapper = styled.div`
  margin: 40px 0px;
  max-width: 90%;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
`

export const ConfirmVoteWrapper = styled.div`
  max-width: 645px;
  padding: 40px;
  background-color: #050404;
  margin: auto;

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: white;
    margin-bottom: 30px;
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  @media (max-width: 600px) {
    padding: 20px;
    margin: 20px 0px;
  }
`

export const WrapperConfirmButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`

export const ConfirmButton = styled(ActionButton)`
  margin: 5px 0px;
  width: 227px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const BackButton = styled(OutlinedButton)`
  margin: 5px 0px;
  width: 227px;

  @media (max-width: 600px) {
    width: 100%;
  }
`
