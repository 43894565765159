import styled from 'styled-components'
import colors from '../../styles/colors'

export const TxtInfo = styled.p`
  text-align: left;
  font-size: 16px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  line-height: 1.5;
`

export const LimitedDiv = styled.div`
  max-width: 434px;
  width: 95%;
  margin: 40px auto 30px;
`

export const ErrorSpan = styled.div`
  max-width: 434px;
  width: 100%;
  margin: 0 auto;
  color: ${colors.red.primary};
`

export const WrapperTxtInfo = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0px;
`

export const WrapperFields = styled('div')<{first?: boolean}>`
  max-width: 434px;
  width: 100%;
  margin: 15px auto;
  ${(props) => props.first && 'margin-top: 50px'}
`

export const WrapperActionButton = styled.div`
  margin: 50px auto 0px;
  width: 100%;
  max-width: 400px;
  text-align: center;

  button {
    min-width: 70%;
  }
`

export const WrapperForgetButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
export const WrapperRealization = styled.div`
  margin: 50px auto 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`
