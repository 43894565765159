import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import categoriesService from '../../service/categories'
import stepsService from '../../service/steps'
import {useAuth} from '../AuthContext/Context'
import VotationContext, {CurrentCategory} from './Context'
import {Step} from '../../models/Step'
import {Category} from '../../models/Categories'
import voteService from '../../service/vote'
import {Vote} from '../../models/Vote'
import {useApp} from '../AppContext/Context'
import {toast} from 'react-toastify'
import emailService from '../../service/email'
import {handlerAppIsBlock} from '../../service/utils'
import CustomModal from '../../components/CustomModal'

export const steps = {
  ['Fase 1']: {
    page: '/indicacao',
    order: 1,
  },
  ['Fase 2']: {
    page: '/votacao',
    order: 2,
  },
  ['Fase 3']: {
    page: '/votacao',
    order: 3,
  },
}

const VotationProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [currentStep, setCurrentStep] = useState<Step>()
  const [currentCategory, setCurrentCategory] = useState<CurrentCategory>()
  const [indexCurrentCategory, setIndexCurrentCategory] = useState(0)
  const [categories, setCategories] = useState<Category[]>([])
  const [votes, setVotes] = useState<Vote[]>([])
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
  const {user, logout} = useAuth()
  const {setIsLoading} = useApp()
  const history = useHistory()
  const [limitedAccess, setLimitedAccess] = useState(false)
  const [modal, setModal] = useState("")

  useEffect(() => {
    if (!user) return
    setIsLoading(true)

    const checkRole = (step: Step) => {
      if (user.role === 'Comum') {
        if(step.stage === 'Fase 1'){
          setModal("F1msg");
          setIsLoading(false)
          logout()
        }else if(step.stage === 'Fase 3'){
          setModal("F3msg")
          setLimitedAccess(true);
        }
      }
    }

    const getStep = async () => {
      try {
        const {data} = await stepsService.getCurrentStep({token: user.token})
        const step = {
          ...steps[data.stage as 'Fase 1' | 'Fase 2' | 'Fase 3'],
          id: data.id,
          stage: data.stage,
        }

        checkRole(step as Step)

        setCurrentStep(step as Step)
      } catch (error) {
        handlerAppIsBlock(error)
      }
    }

    getStep()
  }, [user])

  useEffect(() => {
    if (!user || !currentStep) return

    const getVotesAndCategories = async () => {
      try {
        const {data, userVotes} =
          await categoriesService.getWithVotesAndPartner({
            token: user.token,
            userId: user.id,
            order: currentStep.order,
            stageId: currentStep.id,
          })

        setCategories(data)
        setVotes(userVotes)
      } catch (error) {
        handlerAppIsBlock(error, logout)
      } finally {
        setIsLoading(false)
      }
    }

    getVotesAndCategories()
  }, [user, currentStep])

  const updateCurrentCategory = useCallback(() => {
    const currentCategory = categories[indexCurrentCategory]
    const currentVotes = votes.filter(
      (vote) => vote.categoryId === currentCategory.id,
    ).slice(0, 5);

    setCurrentCategory({
      category: currentCategory,
      vote: currentVotes ? currentVotes : [],
    })

    setPrevButtonDisabled(indexCurrentCategory === 0)
    setNextButtonDisabled(indexCurrentCategory + 1 === categories.length)
  }, [categories, votes, indexCurrentCategory])

  useEffect(() => {
    if (!categories.length || !votes.length) {
      setPrevButtonDisabled(true)
      setNextButtonDisabled(true)
      return
    }

    updateCurrentCategory()
  }, [categories, votes, indexCurrentCategory, updateCurrentCategory])

  const onClickPrevButton = () => {
    setIndexCurrentCategory((prevValue) => prevValue - 1)
  }

  const onClickNextButton = () => {
    setIndexCurrentCategory((prevValue) => prevValue + 1)
  }

  const changeCategory = (categoryId: string) => {
    if (!categoryId || !categories.length) return

    const index = categories.findIndex((cat) => cat.id === categoryId)

    setIndexCurrentCategory(index > 0 ? index : 0)
  }

  const makeIndication = async (indication: string, linkedin: string) => {
    try {
      const newVotes = [...votes]
      const vote = {
        indication,
        linkLinkedin: linkedin,
        user: user?.email,
        categoryId: currentCategory?.category?.id,
        main: true,
        token: user?.token,
      }

      const {data} = await voteService.indicate(vote)

      const emptyCategory = newVotes.findIndex((e)=>(
        e.categoryId === currentCategory?.category?.id && !e.voted
      ));

      const voteAttrs = {
        categoryName: currentCategory?.category?.name || '',
        categoryId: currentCategory?.category?.id || '',
        indicationId: data.id,
        indicationName: indication,
        linkedin,
        voted: true,
      }

      if(emptyCategory !== -1){
        newVotes[emptyCategory] = voteAttrs;
      }else{
        newVotes.push(voteAttrs)
      }



      setVotes(newVotes)

      // if (newVotes.filter((vote) => vote.voted).length === categories.length) {
      //   history.push('/agradecimento')
      // }

      if (newVotes.filter((vote) => vote.voted).length === 1) {
        sendEmailThanks()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sendEmailThanks = () => {
    if (!user) return

    const body = {
      type: 'voted',
      email: user.email,
      token: user.token,
    }

    emailService.sendEmail(body)
  }

  const vote = async (indication: string) => {
    if (!user || !currentStep || !currentCategory || !currentCategory.category)
      return

    const body = {
      userId: user.id,
      token: user.token,
      categoryId: currentCategory.category.id,
      stageId: currentStep.id,
      indicationId: indication,
    }

    const newVotes = votes

    newVotes[indexCurrentCategory] = {
      categoryName: currentCategory?.category?.name || '',
      categoryId: currentCategory?.category?.id || '',
      indicationId: indication,
      indicationName: '',
      voted: true,
    }

    setVotes(newVotes)

    updateCurrentCategory()

    await voteService.poll(body)

    if (newVotes.filter((vote) => vote.voted).length === 1) {
      sendEmailThanks()
    }

    if (newVotes.filter((vote) => vote.voted).length === categories.length) {
      history.push('/agradecimento')
    }
  }

  return (
    <>
      <VotationContext.Provider
        value={{
          currentStep,
          categories,
          votes,
          currentCategory,
          makeIndication,
          vote,
          changeCategory,
          limitedAccess: limitedAccess,
          prevButton: {
            onClick: onClickPrevButton,
            disable: prevButtonDisabled,
          },
          nextButton: {
            onClick: onClickNextButton,
            disable: nextButtonDisabled,
          },
        }}>
        {children}
      </VotationContext.Provider>

      {modal ? (
        <CustomModal
          type={ modal === "F1msg" ? "error" : "default"}
          title={ modal === "F1msg" ? "ATENÇÃO!" : ""}
          center={modal === "F3msg"}
          onClose={()=>setModal("")}
        >

          {
            modal === "F1msg" ? (
              <>
                <p>Obrigado pelo seu login! Neste momento, a fase de indicação de empresas e pessoas para concorrerem ao Startup Awards 24 é feita apenas pela Academia Startup Awards.</p>
                <p>Você poderá votar assim que os 10 mais indicados de cada categoria forem anunciados! Acompanhe nossas redes para saber mais detalhes!</p>
                <p>Startup Awards 2k24. É coisa nossa.</p>
              </>
            ) : modal === "F3msg" ? (
              <>
                <p>No estágio atual a academia está votando. <br/>
                Mas você pode visualizar os concorrentes.
              </p>
              </>
            ) : <></>
          }
        </CustomModal>
      ) : <></>}
    </>
  )
}

export default VotationProvider
