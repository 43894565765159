import React, { useContext } from "react";
import { Step } from "../../models/Step";
import { Category } from '../../models/Categories';
import { Vote } from '../../models/Vote';


interface PaginationButton {
  onClick(): void
  disable: boolean
}

export interface CurrentCategory {
  category: Category | undefined
  vote: (Vote | undefined)[]
}

interface VotationContextType {
  currentStep: Step | undefined
  categories: Category[]
  votes: Vote[]
  currentCategory: CurrentCategory | undefined
  makeIndication(indication: string, linkedin: string, index: number): void
  vote(indication: string): void
  changeCategory(categoryId: string): void
  prevButton: PaginationButton
  nextButton: PaginationButton
  limitedAccess: boolean
}

const VotationContext = React.createContext<VotationContextType>({
  currentStep: undefined,
  categories: [],
  votes: [],
  currentCategory: {
    category: undefined,
    vote: []
  },
  changeCategory: () => undefined,
  makeIndication:() => undefined,
  vote:() => undefined,
  prevButton: {
    onClick:() => undefined,
    disable: false
  },
  nextButton: {
    onClick:() => undefined,
    disable: false
  },
  limitedAccess: false
});

export default VotationContext;
export const useVotation = () => useContext(VotationContext);
