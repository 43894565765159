import React, {SelectHTMLAttributes, Ref} from 'react'
import * as S from './styles'

export interface SelectFieldProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  name?: string
  error?: boolean
  fullWidth?: boolean
  options: string[]
  errorMessage?: string
  inputRef?: Ref<HTMLSelectElement> | null | undefined
}

const SelectField: React.FC<SelectFieldProps> = (props) => {
  return (
    <S.WrapperField>
      {props.label && <label>{props.label}</label>}
      <S.SelectField {...props} ref={props.inputRef}>
        {props.options &&
          props.options.map((opt) => <option key={opt}>{opt}</option>)}
      </S.SelectField>
      {props.error && <S.ErrorMessage>{props.errorMessage}</S.ErrorMessage>}
    </S.WrapperField>
  )
}

export default SelectField
