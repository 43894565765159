import React, {useState, useEffect} from 'react'

import {useHistory} from 'react-router-dom'

import {useVotation} from '../../context/VotationContext/Context'

import {useAuth} from '../../context/AuthContext/Context'

import AppLoader from '../AppLoader'

// import UserLogin from '../../assets/images/icon/user-menu.svg'
import UserPencil from '../../assets/images/icon/user-pencil.svg'

import * as S from './styles'
// import {LoginForm} from '../../pages/Login'
import OutlinedButton from '../Buttons/OutlinedButton'

//Login Imports
import ReCAPTCHA from 'react-google-recaptcha'
import TextField from '../../components/Inputs/TextField'
import PasswordField from '../../components/Inputs/PasswordField'
import NewButton from '../../components/Buttons/NewButton'
import LinkButton from '../../components/Buttons/LinkButton'
import * as LoginS from '../../pages/Login/styles'
import recaptchaService from '../../service/recaptcha'

// icons
import { PiUserBold } from "react-icons/pi";

export type menuShow = boolean

const Menu: React.FC = () => {

  const [loadingVotes, setLoadingVotes] = useState(true)

  interface VoteCategory {
    categoryId: string;
    categoryName: string;
    voted: boolean;
  }

  const [categoriesWithVotes, setCategoriesWithVotes] = useState<VoteCategory[]>([])

  const history = useHistory()

  const {logout, userIsLogged, user, isOpen, setIsOpen} = useAuth()

  const {votes, currentStep, changeCategory} = useVotation()

  useEffect(() => {
    if (!votes.length) {
      setLoadingVotes(true)
    } else {
      const votesFiltrado = votes.reduce<VoteCategory[]>((r, obj) => {
        const exist = r.find(item => item.categoryId === obj.categoryId);
        if (!exist) {
          r.push({
            categoryId: obj.categoryId,
            categoryName: obj.categoryName,
            voted: typeof obj.voted === 'boolean' ? obj.voted : false,
          });
        } else if (!exist.voted && typeof obj.voted === 'boolean' && obj.voted) {
          r[r.indexOf(exist)] = {
            categoryId: obj.categoryId,
            categoryName: obj.categoryName,
            voted: true,
          };
        }
        return r;
      }, []);
      setCategoriesWithVotes(votesFiltrado)
      setLoadingVotes(false)
    }
  }, [votes, changeCategory])

  return (
    <S.MenuWrapper active={isOpen}>
      <S.MenuContainer>
        <S.XIcon onClick={() => setIsOpen(false)} />
        <S.MenuHead>
          {userIsLogged() ? (
            <>
              <S.MenuHeadLogin
                onClick={() => {
                  history.push('/perfil')
                  setIsOpen(false)
                }}>
                <div className="icon">
                  <PiUserBold />
                </div>
                <img className="pencil" src={UserPencil} title="" alt="" />
                <span>{user?.name}</span>
              </S.MenuHeadLogin>
              <S.MenuHeadRegister
                onClick={() => {
                  logout()
                  setIsOpen(false)
                }}>
                Sair
              </S.MenuHeadRegister>
            </>
          ) : (
            <>
              <S.MenuHeadLogin
                onClick={() => {
                  history.push('/login')
                  setIsOpen(false)
                }}>
                <div className="icon">
                  <PiUserBold />
                </div>
                <span>Entrar</span>
              </S.MenuHeadLogin>
              <S.MenuHeadRegister
                onClick={() => {
                  history.push('/cadastrar')
                  setIsOpen(false)
                }}>
                Cadastrar
              </S.MenuHeadRegister>
            </>
          )}
        </S.MenuHead>
        {!userIsLogged() && (
          <S.MenuLogin>
            <S.MenuLoginContent>
              <S.MenuLoginContentTitle>
                Olá, bem-vinde de volta ;)
              </S.MenuLoginContentTitle>
              <LoginForm />
              <S.MenuLoginRegister>
                <p>Ou</p>
                <OutlinedButton
                  onClick={() => {
                    history.push('/cadastrar')
                    setIsOpen(false)
                  }}>
                  Cadastrar
                </OutlinedButton>
              </S.MenuLoginRegister>
            </S.MenuLoginContent>
          </S.MenuLogin>
        )}
        <S.MenuItensWrapper>
          <S.MenuItens>
            {userIsLogged() && (
              <li
                onClick={() => {
                  history.push('/perfil')
                  setIsOpen(false)
                }}>
                Meus dados
              </li>
            )}
            <li
              onClick={() => {
                history.push('/regulamento')
                setIsOpen(false)
              }}>
              Regulamento
            </li>
            <li
              onClick={() => {
                history.push('/seguranca-e-privacidade')
                setIsOpen(false)
              }}>
              Segurança e Privacidade
            </li>
            {userIsLogged() && currentStep && currentStep.order !== 2 && (
              <li>
                <a
                  href="/files/regras-da-academia-startup-awards.pdf"
                  target="_blank"
                  rel="noopener noreferrer">
                  Regras da Academia
                </a>
              </li>
            )}
            <li
              onClick={() => {
                history.push('/historico-do-evento')
                setIsOpen(false)
              }}>
              Histórico do Evento
            </li>
            {userIsLogged() && (
              <li
                onClick={() => {
                  logout()
                  setIsOpen(false)
                }}>
                Sair
              </li>
            )}
          </S.MenuItens>
        </S.MenuItensWrapper>
        {userIsLogged() && (
          <S.MenuVotesWrapper>
            {loadingVotes ? (
              <AppLoader />
            ) : (
              <>
                <S.MenuVotesTitle>Categorias</S.MenuVotesTitle>
                <S.MenuVotesContainer>
                  {categoriesWithVotes.map((vote) => (
                    <S.MenuVotesCard
                      onClick={() => {
                        changeCategory(vote.categoryId)
                        currentStep?.page && history.push(`${currentStep.page}`)
                        setIsOpen(false)
                      }}
                      key={`menu-vote-${vote.categoryId}`}>
                      <p>{vote.categoryName}</p>
                      <S.MenuVotesCardBullet active={vote.voted} />
                    </S.MenuVotesCard>
                  ))}
                </S.MenuVotesContainer>
              </>
            )}
          </S.MenuVotesWrapper>
        )}
      </S.MenuContainer>
    </S.MenuWrapper>
  )
}
export default Menu

export const LoginForm: React.FC = () => {
  const history = useHistory()
  const {login, isLogging, setIsOpen} = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [recaptchaIsValid, setRecaptchaIsValid] = useState(false)
  const [loadRecaptcha, setLoadRecaptcha] = useState(false)

  const [recaptchaError, setRecaptchaError] = useState(false)

  async function onChangeCaptcha(value: string | null) {
    const {success} = await recaptchaService.validate({
      value,
    })

    if (success && success === true) {
      setRecaptchaIsValid(true)
      setRecaptchaError(false)
    } else {
      setRecaptchaIsValid(false)
      setRecaptchaError(true)
    }
  }

  useEffect(() => {
    if (recaptchaIsValid === true) {
      setRecaptchaError(false)
    }
  }, [recaptchaIsValid])

  useEffect(() => setLoadRecaptcha(true), [])

  return (
    <div>
      <LoginS.WrapperFields>
        <TextField
          label="E-mail"
          value={email}
          onChange={({target}) => setEmail(target.value)}
        />
      </LoginS.WrapperFields>
      <LoginS.WrapperFields>
        <PasswordField
          label="Senha"
          value={password}
          onChange={({target}) => setPassword(target.value)}
        />
      </LoginS.WrapperFields>
      <LoginS.WrapperForgetButton>
        <LinkButton
          onClick={() => {
            setIsOpen(false)
            history.push('/esqueci-a-senha')
          }}>
          ESQUECI MINHA SENHA
        </LinkButton>
      </LoginS.WrapperForgetButton>
      {loadRecaptcha && (
        <LoginS.WrapperRecaptcha>
          <ReCAPTCHA
            sitekey="6Lep34ohAAAAAMK_COExiyLlhIQRmTponouFpzga"
            onChange={onChangeCaptcha}
            theme="dark"
          />
          {recaptchaError && (
            <LoginS.RecaptchaError>
              Por favor confirme que você não é um robô.
            </LoginS.RecaptchaError>
          )}
        </LoginS.WrapperRecaptcha>
      )}

      <LoginS.WrapperBtnEntrar>
        <NewButton
          isLoading={isLogging}
          onClick={() => {
            if (!recaptchaIsValid) {
              setRecaptchaError(true)
            } else {
              login(email, password, recaptchaIsValid)
            }
          }}>
          ENTRAR
        </NewButton>
      </LoginS.WrapperBtnEntrar>
    </div>
  )
}
