import React, {HTMLAttributes} from 'react'
import * as S from './styles'

export interface OutlinedButton extends HTMLAttributes<HTMLButtonElement> {
  isGray?: boolean
}

const OutlinedButton: React.FC<OutlinedButton> = ({children, ...props}) => {
  return <S.Button {...props}>{children}</S.Button>
}

export default OutlinedButton
