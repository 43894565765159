import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router'
import ReCAPTCHA from 'react-google-recaptcha'
import {Container} from '../../styles/globals'
import * as S from './styles'
import TextField from '../../components/Inputs/TextField'
import PasswordField from '../../components/Inputs/PasswordField'
import NewButton from '../../components/Buttons/NewButton'
import LinkButton from '../../components/Buttons/LinkButton'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import Realization from '../../components/Realization'
import {useAuth} from '../../context/AuthContext/Context'
import recaptchaService from '../../service/recaptcha'

const Login: React.FC = () => {
  const history = useHistory()

  return (
    <>
      <Container>
        <S.LoginWrapper>
          <S.TxtInfo>
            Olá ;) <br />
            Bem-vinde de volta
          </S.TxtInfo>
          <LoginForm />
          <S.SemConta>Ainda não possui cadastro?</S.SemConta>
          <S.WrapperRegisterButton>
            <OutlinedButton onClick={() => history.push('/cadastrar')}>
              Cadastrar
            </OutlinedButton>
          </S.WrapperRegisterButton>
          <Realization />
        </S.LoginWrapper>
      </Container>
    </>
  )
}

export default Login

export const LoginForm: React.FC = () => {
  const history = useHistory()
  const {login, isLogging, setIsOpen} = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [recaptchaIsValid, setRecaptchaIsValid] = useState(false)
  const [loadRecaptcha, setLoadRecaptcha] = useState(false)

  const [recaptchaError, setRecaptchaError] = useState(false)

  async function onChangeCaptcha(value: string | null) {
    const {success} = await recaptchaService.validate({
      value,
    })

    if (success && success === true) {
      setRecaptchaIsValid(true)
      setRecaptchaError(false)
    } else {
      setRecaptchaIsValid(false)
      setRecaptchaError(true)
    }
  }

  useEffect(() => {
    if (recaptchaIsValid === true) {
      setRecaptchaError(false)
    }
  }, [recaptchaIsValid])

  useEffect(() => setLoadRecaptcha(true), [])

  return (
    <div>
      <S.WrapperFields>
        <TextField
          label="E-mail"
          value={email}
          onChange={({target}) => setEmail(target.value)}
        />
      </S.WrapperFields>
      <S.WrapperFields>
        <PasswordField
          label="Senha"
          value={password}
          onChange={({target}) => setPassword(target.value)}
        />
      </S.WrapperFields>
      <S.WrapperForgetButton>
        <LinkButton
          onClick={() => {
            setIsOpen(false)
            history.push('/esqueci-a-senha')
          }}>
          ESQUECI MINHA SENHA
        </LinkButton>
      </S.WrapperForgetButton>
      {loadRecaptcha && (
        <S.WrapperRecaptcha>
          <ReCAPTCHA
            sitekey="6Lep34ohAAAAAMK_COExiyLlhIQRmTponouFpzga"
            onChange={onChangeCaptcha}
            theme="dark"
          />
          {recaptchaError && (
            <S.RecaptchaError>
              Por favor confirme que você não é um robô.
            </S.RecaptchaError>
          )}
        </S.WrapperRecaptcha>
      )}

      <S.WrapperBtnEntrar>
        <NewButton
          isLoading={isLogging}
          onClick={() => {
            if (!recaptchaIsValid) {
              setRecaptchaError(true)
            } else {
              login(email, password, recaptchaIsValid)
            }
          }}>
          ENTRAR
        </NewButton>
      </S.WrapperBtnEntrar>
    </div>
  )
}
