import React from 'react'
import {Route, Redirect, RouteProps} from 'react-router-dom'
import {useAuth} from '../../context/AuthContext/Context'

interface Props extends RouteProps {
  children: React.ReactNode
}

const PrivateRoute: React.FC<Props> = ({children, ...props}) => {
  const {userIsLogged} = useAuth()

  const Page = children

  return (
    <Route
      {...props}
      render={({location}) =>
        userIsLogged() ? (
          Page
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location},
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
