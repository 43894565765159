import axios from 'axios';
import {STEPS} from './constants';
import {handleError} from './utils';

const stepsService = {
  getCurrentStep: params => {
    return new Promise((resolve, reject) => {
      axios
        .get(STEPS.GET_CURRENT_STEP, {
          headers: {
            'x-access-token': params.token,
          },
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
};

export default stepsService;
