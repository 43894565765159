import React from 'react'
import * as S from './styles'
// import imgLoader from '../../assets/images/loader2022.gif'
import imgLoader from '../../assets/images/stawards-loader-bg-preto.gif'

const AppLoader: React.FC = () => {
  return (
    <S.WrapperLoader>
      <img src={imgLoader} />
    </S.WrapperLoader>
  )
}

export default AppLoader
