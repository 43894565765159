import axios from 'axios'
import {EMAIL} from './constants'
import {handleError} from './utils'

const emailService = {
  sendEmail: (params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(EMAIL.SEND_EMAIL, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error.response)
          reject(error.response)
        })
    })
  },
  sendEmailMediaKit: (params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(EMAIL.SEND_EMAIL_MEDIA_KIT, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error.response)
          reject(error.response)
        })
    })
  },
}

export default emailService
