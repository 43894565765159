import styled from 'styled-components'

export const WrapperLoader = styled.div`
  width: 100%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 150px;
    border-radius: 60px;
  }
`
