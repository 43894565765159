// Capas

import image2023 from '../assets/images/historico/capa-2023.jpg'
import image2022 from '../assets/images/historico/capa-2022.jpg'
import image2021 from '../assets/images/historico/capa-2021.jpg'
import image2020 from '../assets/images/historico/capa-2020.jpg'
import image2019 from '../assets/images/historico/capa-2019.jpg'
import image2018 from '../assets/images/historico/capa-2018.jpg'
import image2017 from '../assets/images/historico/capa-2017.jpg'
import image2016 from '../assets/images/historico/capa-2016.jpg'
import image2015 from '../assets/images/historico/capa-2015.jpg'
import image2014 from '../assets/images/historico/capa-2014.jpg'
import image2013 from '../assets/images/historico/capa-2013.jpg'

// Fotos ano

import i201601 from '../assets/images/historico/2016-01.jpg'
import i201602 from '../assets/images/historico/2016-02.jpg'
import i201603 from '../assets/images/historico/2016-03.jpg'
import i201604 from '../assets/images/historico/2016-04.jpg'
import i201605 from '../assets/images/historico/2016-05.jpg'
import i201606 from '../assets/images/historico/2016-06.jpg'

import i201701 from '../assets/images/historico/2017-01.jpg'
import i201702 from '../assets/images/historico/2017-02.jpg'
import i201703 from '../assets/images/historico/2017-03.jpg'
import i201704 from '../assets/images/historico/2017-04.jpg'
import i201705 from '../assets/images/historico/2017-05.jpg'
import i201706 from '../assets/images/historico/2017-06.jpg'

import i201801 from '../assets/images/historico/2018-01.jpg'
import i201802 from '../assets/images/historico/2018-02.jpg'
import i201803 from '../assets/images/historico/2018-03.jpg'
import i201804 from '../assets/images/historico/2018-04.jpg'
import i201805 from '../assets/images/historico/2018-05.jpg'
import i201806 from '../assets/images/historico/2018-06.jpg'

import i201901 from '../assets/images/historico/2019-01.jpg'
import i201902 from '../assets/images/historico/2019-02.jpg'
import i201903 from '../assets/images/historico/2019-03.jpg'
import i201904 from '../assets/images/historico/2019-04.jpg'
import i201905 from '../assets/images/historico/2019-05.jpg'
import i201906 from '../assets/images/historico/2019-06.jpg'

import i202001 from '../assets/images/historico/2020-01.jpg'
import i202002 from '../assets/images/historico/2020-02.jpg'
import i202003 from '../assets/images/historico/2020-03.jpg'
import i202004 from '../assets/images/historico/2020-04.jpg'
import i202005 from '../assets/images/historico/2020-05.jpg'
import i202006 from '../assets/images/historico/2020-06.jpg'

import i202101 from '../assets/images/historico/2021-01.jpg'
import i202102 from '../assets/images/historico/2021-02.jpg'
import i202103 from '../assets/images/historico/2021-03.jpg'
import i202104 from '../assets/images/historico/2021-04.jpg'
import i202105 from '../assets/images/historico/2021-05.jpg'
import i202106 from '../assets/images/historico/2021-06.jpg'

import i202201 from '../assets/images/historico/2022-01.jpg'
import i202202 from '../assets/images/historico/2022-02.jpg'
import i202203 from '../assets/images/historico/2022-03.jpg'
import i202204 from '../assets/images/historico/2022-04.jpg'
import i202205 from '../assets/images/historico/2022-05.jpg'
import i202206 from '../assets/images/historico/2022-06.jpg'

import i202301 from '../assets/images/historico/2023-01.jpg'
import i202302 from '../assets/images/historico/2023-02.jpg'
import i202303 from '../assets/images/historico/2023-03.jpg'
import i202304 from '../assets/images/historico/2023-04.jpg'
import i202305 from '../assets/images/historico/2023-05.jpg'
import i202306 from '../assets/images/historico/2023-06.jpg'

export interface HistoricoItem {
  year: number | string
  mainImage: string
  title?: string
  text?: string
  winners: string[]
  images?: string[]
}

const historicoItems: HistoricoItem[] = [
  {
    year: 2023,
    title: 'Startup Awards 2023',
    mainImage: image2023,
    winners: [
      'Mentora do ano: Renata Luz',
      'Venture Capital do ano: ACE Ventures',
      'Corporate do ano: Natura',
      'Heroína do ano: Vanessa Pessoa',
      'Agente de fomento do ano: Sebrae',
      'Instituição de crescimento do ano: Sebrae',
      'Investidora anjo do ano: Camila Farani',
      'Iniciativa de educação do ano: Alura',
      'Hub de tecnologia do ano: Cubo',
      'Profissional de imprensa do ano: Beatriz Bevilaqua',
      'Startup revelação do ano: Blindog',
      'Startup do ano: Exact Sales',
      'Comunidade revelação do ano: Açaí Valley',
      'Comunidade do ano: Rapadura Valley'
    ],
    images: [i202301, i202302, i202303, i202304, i202305, i202306],
  },
  {
    year: 2022,
    title: 'Startup Awards 2022',
    mainImage: image2022,
    winners: [
      'Comunidade do ano: Rapadura Valley',
      'Comunidade Revelação: Tucuju Valley',
      'Investidor/Investidora Anjo: Claudia Rosa',
      'Profissional de Imprensa: Fernanda Santos',
      'Educação: 49 Educação',
      'Universidade: UFSC',
      'Hub de Inovação: Acate',
      'Venture Capital: investidores.vc',
      'Impacto Social: Se Candidate, Mulher!',
      'Corporate: Ambev',
      'Mentor/Mentora do Ano: Fabiano Nagamatsu',
      'Herói/Heroína do Ano: Monnaliza Medeiros',
      'Startup Revelação: Sapiens',
      'Startup do Ano: Remessa Online',
      'Aceleradora: Sebrae for Startups',
    ],
    images: [i202201, i202202, i202203, i202204, i202205, i202206],
  },
  {
    year: 2021,
    title: 'Startup Awards 2021',
    mainImage: image2021,
    winners: [
      'Comunidade do ano: Tambaqui Valley',
      'Comunidade Revelação: Jaraqui Valley',
      'Investidor/Investidora Anjo: Rafaela Bassetti',
      'Profissional de Imprensa: Beatriz Bevilaqua',
      'Educação: Gama Academy',
      'Universidade: Universidade de São Paulo (USP)',
      'Hub de Inovação: B2mamy',
      'Venture Capital: Bossanova Investimentos',
      'Impacto Social: Se Candidate, Mulher!',
      'Corporate: Ambev',
      'Mentor/Mentora do Ano: Ivy Cristiny',
      'Herói/Heroína do Ano: Camila Forte',
      'Startup Revelação: Hermoney',
      'Startup do Ano: Gama Academy',
      'Aceleradora: Darwin Startups',
    ],
    images: [i202101, i202102, i202103, i202104, i202105, i202106],
  },
  {
    year: 2020,
    title: 'Startup Awards 2020',
    mainImage: image2020,
    winners: [
      'Comunidade do ano: Rapadura Valley (CE)',
      'Comunidade Revelação: Tambaqui Valley (RO)',
      'Investidor/Investidora Anjo: João Kepler Braga (SP)',
      'Profissional de Imprensa: Bruno Pinheiro (SP)',
      'Educação: Gama Academy (SP)',
      'Universidade: FGV',
      'Hub de Inovação: Distrito',
      'Venture Capital: Bossa Nova Investimentos',
      'Impacto Social: Se Candidate, Mulher!',
      'Corporate: AMBEV',
      'Mentor/Mentora do Ano: Rafael Ribeiro',
      'Herói/Heroína do Ano: Lindália Junqueira',
      'Startup Revelação: Transfeera',
      'Startup do Ano: Shawee',
      'Aceleradora: Darwin Startups',
    ],
    images: [i202001, i202002, i202003, i202004, i202005, i202006],
  },
  {
    year: 2019,
    title: 'Startup Awards 2019',
    mainImage: image2019,
    winners: [
      'Aceleradora: Darwin Startups',
      'Comunidade do Ano: Startup SC',
      'Comunidade Revelação: Rapadura Valley',
      'Corporate: Accenture',
      'Educação: Gama Academy',
      'Herói (a): Dani Junco',
      'HUB de Inovação: ACATE',
      'Impacto Social: Programaria',
      'Investidor (a) Anjo (a): Marco Poli',
      'Mentor (a): Vinícius Machado',
      'Imprensa: Beatriz Bevilaqua',
      'Startup do Ano: Agenda Edu',
      'Startup Revelação: Apponte./me',
    ],
    images: [i201901, i201902, i201903, i201904, i201905, i201906],
  },
  {
    year: 2018,
    title: 'Startup Awards 2018',
    mainImage: image2018,
    winners: [
      'Investidor Anjo: Camila Farani',
      'Profissional de imprensa: Bruno Pinheiro, da PiaR',
      'Universidade: UFMG',
      'Coworking: Associação Catarinense de Tecnologia (ACATE)',
      'Aceleradora: Darwin',
      'Impacto: Black Rocks',
      'Mentor: Paulo Costa, da Accenture',
      'Corporate: Sebrae',
      'Herói/Heroína do Ano: Alexandre Souza, do Sebrae SC',
      'Startup do Ano: Edools',
    ],
    images: [i201801, i201802, i201803, i201804, i201805, i201806],
  },
  {
    year: 2017,
    title: 'Startup Awards 2017',
    mainImage: image2017,
    winners: [
      'Investidor-anjo: João Kepler',
      'Profissional de Imprensa: Monique Fernandes (Tagarela)',
      'Universidade: UFMG',
      'Coworking:  CUBO',
      'Aceleradora: Startup Farm',
      'Impacto: Mete a Colher',
      'Mentor: Edson Mackenzie',
      'Corporate: Itaú',
      'Herói/Heroína: Michel Porcino',
      'Startup do Ano: MaxMilhas',
      'Comunidade: Comunidade de Startups de São Paulo',
    ],
    images: [i201701, i201702, i201703, i201704, i201705, i201706],
  },
  {
    year: 2016,
    title: 'Startup Awards 2016',
    mainImage: image2016,
    winners: [
      'Melhor mentor: Rafael Assunção',
      'Assessoria jurídica: Natalie Witte',
      'Assessoria de imprensa: Dialetto',
      'Melhor reportagem: Revista Exame',
      'Coworking: Google Campus',
      'Equipe fundadora: Méliuz',
      'Fundo de investimento: Monashees',
      'Universidade: UFSC',
      'Melhor comunidade de startups: Startup SC',
      'Investidor-anjo: Camila Farani',
      'Aceleradora: InovAtiva',
      'Menção honrosa: Pareto Group',
      'Startup do ano: Méliuz',
    ],
    images: [i201601, i201602, i201603, i201604, i201605, i201606],
  },
  {
    year: 2015,
    title: 'Spark Awards 2015',
    mainImage: image2015,
    winners: [
      'Assessoria de imprensa: Dialetto Comunicação',
      'Assessoria Jurídica: Velloza e Girotto Advogados Associados',
      'Melhor Coworking: Cubo',
      'Melhor reportagem: “O futuro é agora!”, (PEGN)',
      'Melhor universidade: Estácio',
      'Melhor comunidade de startups: San Pedro Valley',
      'Melhor mentor: Ana Fontes',
      'Aceleradora: Espaço Nave',
      'Investidor Anjo: João Kepler',
      'Fundo de investimentos: Redpoint eventures',
      'Equipe Fundadora: Chefsclub',
      'Startup do ano: Sympla',
    ],
  },
  {
    year: 2014,
    title: 'Spark Awards 2014',
    mainImage: image2014,
    winners: [
      'Investidor Anjo: Fernando Campos',
      'Aceleradora: Aceleratech',
      'Fundo de investimento: Grid Investments',
      'Coworking: Aldeia Coworking',
      'Assessoria jurídica: Nabarro & Pfeferman Advogados',
      'Melhor universidade para empreendedores: UniCEUB',
      'Assessoria de imprensa: NR-7',
      'Melhor comunidade de startups: San Pedro Valley',
      'Equipe fundadora: Gabriel, Sergio e Sabrina Nibo',
      'Startup do ano: Pagar.me',
    ],
  },
  {
    year: 2013,
    title: 'Spark Awards 2013',
    mainImage: image2013,
    winners: [
      'Anjo do Ano: Cassio Spina',
      'Melhor Aceleradora: Wayra Brasil',
      'Fundo de Investimento do Ano: Rocket Internet',
      'Melhor Coworking: Fiap Coworking',
      'Melhor Parceiro Jurídico: Aloisio Lopes Advogados',
      'Melhor Parceiro de Comunicação & Marketing: NR-7 Comunicação',
      'Empreendedor do Ano: Bel Pesce',
      'Startup do Ano: Get Ninjas',
      'Startup Latina: Easy Taxi',
      'Melhor Cidade para Startup: São Paulo (SP)',
      'Melhor Universidade / Faculdade Empreendedora: UNIFEI – Universidade Federal de Itajubá',
      'Melhor Evento de Apoio a Startups: Startup Weekend',
    ],
  },
]

export default historicoItems
