import React from 'react'
import * as S from './styles'
import {Container} from '../../styles/globals'

import Awards24Logo from '../../assets/images/logos/footer-logo-2K24.svg'
import AbsLogo from '../../assets/images/logos/abs-logotipo-negativo.svg'
// import BlankoLogo from '../../assets/images/logos/blanko-logotipo-negativo.svg'
//import FacebookIcon from '../../assets/images/icon/facebook.svg'
// import InstagramIcon from '../../assets/images/icon/instagram.svg'
// import LinkedInIcon from '../../assets/images/icon/linkedin.svg'

import { PiLinkedinLogo } from "react-icons/pi";
import { PiInstagramLogo } from "react-icons/pi";

const Footer: React.FC = () => {
  return (
    <S.Footer>
      <Container>
        <S.MainFooter>
          <img src={Awards24Logo} alt="Startup Awards" title="Startup Awards" />
          <div className="links">
            <a href="/files/regras-da-academia-startup-awards.pdf" target="_blank" title="Regras da Academia">
              Regras da Academia
            </a>
            <a href="/files/dinamica-startup-awards.pdf" target="_blank" title="Dinâmica">
              Dinâmica
            </a>
            <a href="/regulamento" title="Regulamento">
              Regulamento
            </a>
            <a href="/seguranca-e-privacidade" title="Segurança e Privacidade">
              Segurança e Privacidade
            </a>
            <a href="/historico-do-evento" title="Histórico do Evento">
              Histórico do Evento
            </a>
            {/* <a href="/files/ABSTARTUPS_STARTUPAWARDS_LAUDO_DE_AUDITORIA_SIGNED.pdf" target="_blank" title="Laudo de Auditoria">
              Laudo de Auditoria
            </a> */}
            {/* <a href="/academia" title="Academia">
              Academia
            </a> */}
          </div>
        </S.MainFooter>
        <S.SubFooter>
          <S.Realização>
            <p>REALIZAÇÃO</p>
            <div>
              <a
                href="https://abstartups.com.br"
                target="_blank"
                rel="noopener noreferrer"
                title="Abstartups">
                <img src={AbsLogo} alt="Abstartups" title="Abstartups" />
              </a>
              {/* <span>-</span> */}
              {/* <a
                href="https://blanko.be"
                target="_blank"
                rel="noopener noreferrer"
                title="Blanko">
                <img src={BlankoLogo} alt="Blanko" title="Blanko" />
              </a> */}
            </div>
          </S.Realização>
          <S.Siganos>
            <p>Acompanhe todas as novidades</p>
            <div>
              {/* <a href="#" rel="noopener noreferrer" title="Facebook">
                <img src={FacebookIcon} alt="Facebook" title="Facebook" />
              </a> */}
              <a
                href="https://www.instagram.com/startupawardsbr"
                rel="noopener noreferrer"
                target="_blank"
                title="Instagram">
                {/* <img src={InstagramIcon} alt="Instagram" title="Instagram" /> */}
                <PiInstagramLogo title="Instagram"/>
              </a>
              <a
                href="https://www.linkedin.com/company/startupawardsbr"
                rel="noopener noreferrer"
                target="_blank"
                title="LinkedIn">
                {/* <img src={LinkedInIcon} alt="LinkedIn" title="LinkedIn" /> */}
                <PiLinkedinLogo title="LinkedIn" />
              </a>
            </div>
          </S.Siganos>
        </S.SubFooter>
      </Container>
    </S.Footer>
  )
}

export default Footer
