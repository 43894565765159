import axios from 'axios';
import {handleError} from './utils';
import {VOTE} from './constants';

const voteService = {
  indicate: body => {
    return new Promise((resolve, reject) => {
      axios
        .post(VOTE.INDICATE, body)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error);
        });
    });
  },
  poll: body => {
    return new Promise((resolve, reject) => {
      axios
        .post(VOTE.POLL, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          handleError(error.response);
          reject(error);
        });
    });
  },
};

export default voteService;
