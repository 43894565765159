import React, {useContext, Dispatch, SetStateAction} from 'react'
import {User} from '../../models/User'

interface AuthContextContextType {
  userIsLogged(): boolean
  login(email: string, password: string, captcha: boolean): void
  isLogging: boolean
  logout(): void
  user: User | undefined
  isOpen: boolean
  toggleMenu(): void
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const AuthContext = React.createContext<AuthContextContextType>({
  userIsLogged: () => false,
  login: () => undefined,
  isLogging: false,
  logout: () => undefined,
  user: undefined,
  isOpen: false,
  setIsOpen: () => undefined,
  toggleMenu: () => undefined,
})

export default AuthContext
export const useAuth = () => useContext(AuthContext)
