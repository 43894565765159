import React, {useState} from 'react'
import {Col, Container, Row} from 'react-grid-system'
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {toast} from 'react-toastify'
import * as S from './styles'
import TextField from '../../components/Inputs/TextField'
import NewButton from '../../components/Buttons/NewButton'
import AbsLogo from '../../assets/images/logos/abs-logotipo-negativo.svg'
import BlankoLogo from '../../assets/images/logos/blanko-logotipo-negativo.svg'
import {useHistory} from 'react-router'
import LinkButton from '../../components/Buttons/LinkButton'

import userService from '../../service/users'

interface IFormInputs {
  email: string
}

const schema = yup.object({
  email: yup
    .string()
    .min(5, 'Digite pelo menos 5 caracteres')
    .email('Digite um e-mail válido')
    .required('Este campo é obrigatório'),
})

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: IFormInputs) => {
    setLoading(true)

    await userService
      .forgotPassword({
        ...data,
      })
      .then(() => {
        setLoading(false)
        toast.success(
          'Solicitação enviada com sucesso! Verifique sua caixa de entrada.',
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        )
        history.push('/')
      })
      .catch((error) => {
        setLoading(false)

        toast.error(error.data.modal, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  return (
    <>
      <Container component="form" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <S.LimitedDiv>
              <S.TxtInfo>
                Digite seu e-email no campo abaixo e clique em enviar. <br />
                Você receberá um e-mail com o link para recuperar sua senha.
              </S.TxtInfo>
            </S.LimitedDiv>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({field: {onChange, onBlur, value, ref}}) => (
                <S.WrapperFields>
                  <TextField
                    label="E-mail"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    error={!!errors && !!errors.email}
                    errorMessage={
                      !!errors && !!errors.email ? errors.email.message : ''
                    }
                  />
                </S.WrapperFields>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <S.WrapperActionButton>
              <NewButton disabled={loading} isLoading={loading} type="submit">
                Enviar Solicitação
              </NewButton>
            </S.WrapperActionButton>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <S.WrapperForgetButton>
              <LinkButton onClick={() => history.push('/login')}>
                LEMBREI MINHA SENHA
              </LinkButton>
            </S.WrapperForgetButton>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <S.WrapperRealization>
              <S.TxtRealization>CO-REALIZAÇÃO</S.TxtRealization>
              <div>
                <img src={AbsLogo} alt="Abstartups" title="Abstartups" />
                <span> - </span>
                <img
                  src={BlankoLogo}
                  className="blanko"
                  alt="Blanko"
                  title="Blanko"
                />
              </div>
            </S.WrapperRealization>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ForgotPassword
