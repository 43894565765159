import React, { useState } from 'react'
import TextField, { TextFieldProps } from '../TextField'
import * as S from './styles'

//Icons
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";

const PasswordField: React.FC<TextFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }

  // return <TextField {...props} type='password' />
  return (
    <S.PasswordWrapper>
      <TextField {...props} type={showPassword ? 'text' : 'password'} />
      <S.Icon onClick={togglePasswordVisibility}>
        {showPassword ? <IoEyeOff /> : <IoEye />}
      </S.Icon>
    </S.PasswordWrapper>
  )
}

export default PasswordField
