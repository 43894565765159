import React, {useState, useEffect, useRef} from 'react'
// import ReCAPTCHA from 'react-google-recaptcha'
import {Col, Row} from 'react-grid-system'
// import {useForm, Controller} from 'react-hook-form'
// import {yupResolver} from '@hookform/resolvers/yup'
// import * as yup from 'yup'
import {useHistory} from 'react-router-dom'
// import {toast} from 'react-toastify'
import {RouterProps} from 'react-router-dom'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination, Grid as SwiperGrid} from 'swiper'
import * as S from './styles'
import {Container} from '../../styles/globals'
import NewButton from '../../components/Buttons/NewButton'
// import TextField from '../../components/Inputs/TextField'

import {getImgPath} from '../../utils'
import siteService from '../../service/site'

// Images
import dobraXImg from '../../assets/images/bg/dobrax/2024.jpg'
import ColorLogo from '../../assets/images/logos/color-logo-2K24.svg'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'

import timelineItems from '../../config/timeline'
import Footer from '../../components/Footer'
import VideoModal from '../../components/Modal'
import AppLoader from '../../components/AppLoader'
import {useVotation} from '../../context/VotationContext/Context'
import {useAuth} from '../../context/AuthContext/Context'
// import emailService from '../../service/email'
// import recaptchaService from '../../service/recaptcha'

// const VideoBG = React.lazy(() => import('./video'))

// interface IFormInputs {
//   name: string
//   email: string
// }

interface Category {
  createdAt: string
  description: string
  id: string
  image: {
    path: string
  }
  imageId: string
  name: string
  order: number
  partnerId: string
  status: boolean
  updatedAt: string
}

export type modalStatus = boolean

const Home: React.FC<RouterProps> = () => {
  const [modalOpen, setModalOpen] = useState<modalStatus>(false)
  const [categories, setCategories] = useState<Category[]>([])
  const history = useHistory()

  const stepsDobra = useRef<HTMLDivElement | null>(null)
  const executeScroll = () => {
    if (stepsDobra && stepsDobra.current) {
      stepsDobra.current.scrollIntoView({ block: "start", behavior: "smooth" })
    }
  }

  // const [sending, setSending] = useState(false)

  // const [formSuccess, setFormSuccess] = useState(false)

  // const [recaptchaIsValid, setRecaptchaIsValid] = useState(false)

  // const [recaptchaError, setRecaptchaError] = useState(false)

  const {currentStep} = useVotation()
  const {userIsLogged} = useAuth()

  // async function onChangeCaptcha(value: any) {
  //   const {success} = await recaptchaService.validate({
  //     value,
  //   })
  //
  //   if (success && success === true) {
  //     setRecaptchaIsValid(true)
  //     setRecaptchaError(false)
  //   } else {
  //     setRecaptchaIsValid(false)
  //     setRecaptchaError(true)
  //   }
  // }

  // useEffect(() => {
  //   if (recaptchaIsValid === true) {
  //     setRecaptchaError(false)
  //   }
  // }, [recaptchaIsValid])

  const breakPoints = {
    0: {
      slidesPerView: 1.3,
      spaceBetween: 20,
      grid: {
        rows: 1,
      },
    },
    600: {
      slidesPerView: 2.25,
      spaceBetween: 20,
      grid: {
        rows: 1,
      },
    },
    1100: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1300: {
      slidesPerGroup: 1,
      slidesPerView: 3,
      spaceBetween: 76,
    },
    1450: {
      slidesPerGroup: 1,
      slidesPerView: 4,
      spaceBetween: 40,
    },
    1650: {
      slidesPerGroup: 1,
      slidesPerView: 4,
      spaceBetween: 60,
    },
  }

  useEffect(() => {
    const getCategories = async () => {
      try {
        const {data} = await siteService.getCategories()
        setCategories(data)
      } catch (error) {
        console.log('error loading categories')
      }
    }
    getCategories()
  }, [])

  // const formSchema = yup.object({
  //   name: yup
  //     .string()
  //     .min(3, 'Pelo menos 3 caracteres')
  //     .required('Este campo é obrigatório'),
  //   email: yup
  //     .string()
  //     .min(5, 'Pelo menos 5 caracteres')
  //     .email('Digite um e-mail válido')
  //     .required('Este campo é obrigatório'),
  // })

  // const {
  //   control,
  //   handleSubmit,
  //   reset,
  //   formState: {errors},
  // } = useForm<IFormInputs>({
  //   resolver: yupResolver(formSchema),
  //   mode: 'onBlur',
  // })

  // const handleForm = async (data: IFormInputs) => {
  //   setSending(true)
  //
  //   if (recaptchaIsValid !== true) {
  //     setRecaptchaError(true)
  //     setSending(false)
  //     return
  //   }
  //
  //   try {
  //     await emailService.sendEmailMediaKit(data).then(() => {
  //       toast.success('Enviado com sucesso')
  //       setFormSuccess(true)
  //       setSending(false)
  //       reset()
  //     })
  //   } catch (err) {
  //     setSending(false)
  //     toast.error(
  //       'Ocorreu um erro. Tente novamente. Caso o erro persista entre em contato.',
  //     )
  //   }
  // }

  return (
    <>
      <S.Main>
        <S.Topo>
          {/* <Suspense><VideoBG /></Suspense> */}
          <S.ContainerRelative>
            <Row>
              {/* <Visible xs sm>
                <Col sm={12} md={0}>
                  <S.TopoPlay onClick={() => setModalOpen(true)} />
                </Col>
              </Visible> */}
              <Col sm={12} md={6} lg={7}>
                <S.TopoTitle>
                  STARTUP AWARDS 2k24.<br/>Está na hora de reconhecer os melhores.
                </S.TopoTitle>
                <S.TopoText>
                  Sim, senhoras e senhores. Estamos de volta e de cara nova. <br/>
                  Nós estamos vivendo o amadurecimento e a consolidação de um ecossistema único no mundo: robusto, conectado e diverso em todos os sentidos. Tecnologia, inovação e negócios ousados também são coisa nossa!
                </S.TopoText>
                <NewButton
                  // onClick={() => window.open(`https://youtube.com/live/svgbdr5nUvM?feature=share`)}
                  onClick={() => {
                    if (userIsLogged()) {
                      currentStep?.page && history.push(`${currentStep.page}`)
                    } else {
                      history.push(`/login`)
                    }
                  }}
                  style={{marginRight: '20px', marginBottom: '20px'}}
                >
                  {userIsLogged() ? currentStep?.stage === "Fase 1" ? "Indicar" : "Votar" : "Entrar"}
                </NewButton>
                <NewButton
                  onClick={executeScroll}
                  outline
                  // onClick={() => history.push(`/vencedores`)}
                >
                  Confira as Datas
                </NewButton>
              </Col>
            </Row>
            {/* <Hidden xs sm>
              <S.TopoPlay onClick={() => setModalOpen(true)} />
            </Hidden> */}
          </S.ContainerRelative>
        </S.Topo>
        <S.Timeline ref={stepsDobra}>
          <Container>
            <Row>
              <Col sm={12} lg={6} xl={5}>
                <S.TimelineTitle>
                  Confira as etapas da premiação
                </S.TimelineTitle>
                <S.TimelineText>
                  Aqui você acompanha as datas de cada uma das etapas da 12ª edição do Startup Awards
                </S.TimelineText>
                <S.TimelineSubText>
                  O cronograma poderá ser alterado pela ABStartups sem aviso prévio, desde que a alteração seja imprescindível para o bom andamento e a conformidade de todas as etapas do processo.
                </S.TimelineSubText>
                <S.ColorLogo>
                  <img src={ColorLogo} alt="Startup Awards"/>
                </S.ColorLogo>
              </Col>
              <Col sm={12} lg={6} xl={7}>
                <S.TimelineSup>Quando?</S.TimelineSup>
                {timelineItems.map((item) => (
                  <S.TimelineItem
                    key={`${item.dateText}-${item.title}`}
                    className={`${
                      new Date(item.dateEnd) < new Date() ? 'passed' : ''
                    }`}>
                    <S.TimelineItemDate>{item.dateText}</S.TimelineItemDate>
                    <S.TimelineItemLine>
                      <div />
                    </S.TimelineItemLine>
                    <S.TimelineItemContent>
                      <h3>{item.title}</h3>
                      <p>{item.text}</p>
                      {item.btnText && item.btnLink && (
                        <NewButton
                          // disabled={
                          //   new Date(item.dateStart) > new Date() ? true : false
                          // }
                          onClick={() => {
                            item.external
                              ? window.open(item.btnLink, '_blank')
                              : item?.btnLink && history.push(item.btnLink)
                            // new Date(item.dateStart) < new Date()
                            //   ? item.external
                            //     ? window.open(item.btnLink, '_blank')
                            //     : item?.btnLink && history.push(item.btnLink)
                            //   : ''
                          }}>
                          {item.btnText ? item.btnText : 'Ir para etapa'}
                        </NewButton>
                      )}
                    </S.TimelineItemContent>
                  </S.TimelineItem>
                ))}
              </Col>
            </Row>
          </Container>
        </S.Timeline>
        <S.Categorias>
          <Container>
            <Row>
              <Col sm={12} lg={6}>
                <S.CategoriasSup>Categorias</S.CategoriasSup>
                <S.CategoriasTitle>
                  As 15 Categorias da Premiação
                </S.CategoriasTitle>
                <S.CategoriasText>
                  Categorias criadas para reconhecer o trabalho de empresas e pessoas que contribuíram durante o ano para o crescimento e consolidação do ecossistema.
                </S.CategoriasText>
              </Col>
              <Col sm={12}>
                <S.CategoriasSlideWrapper>
                  {categories.length ? (
                    <Swiper
                      observeParents={true}
                      observer={true}
                      pagination={{clickable: true}}
                      modules={[Pagination, SwiperGrid]}
                      breakpoints={breakPoints}
                      grid={{
                        rows: 2,
                        fill: 'row',
                      }}>
                      {categories.map((cat) => (
                        <SwiperSlide key={cat.id}>
                          <CardCategoria data={cat} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <AppLoader />
                  )}
                </S.CategoriasSlideWrapper>
              </Col>
            </Row>
          </Container>
        </S.Categorias>
        <S.DobraX>
          <Container>
            <Row>
              <Col sm={12} lg={6}>
                <S.DobraXTitle>
                  Vamos celebrar juntos a grandeza do nosso ecossistema.
                </S.DobraXTitle>
                <S.DobraXText>
                  {`O Startup Awards é a "foto oficial" do ecossistema de inovação e startups do Brasil. Ano após ano, se consolidou como "o" prêmio de reconhecimento nacional. Só no ano passado, a plataforma Startup Awards recebeu mais de 65.000 acessos de todas as regiões do país. O Startup Awards é #coisanossa.`}
                </S.DobraXText>
              </Col>
              <Col sm={12} lg={6}>
                <img
                  src={dobraXImg}
                  alt=""
                  title="Vamos celebrar juntos a resiliência do nosso ecossistema?"
                />
              </Col>
            </Row>
          </Container>
        </S.DobraX>
        {/* <S.Form>
          <Container>
            <Row>
              <Col sm={12} lg={6}>
                <S.FormTitle>
                  Sua marca na maior premiação do ecossistema de inovação
                  brasileiro
                </S.FormTitle>
                <S.FormText>
                  Já pensou em inserir sua marca na 11ª edição da maior
                  premiação do Startup Awards? Preencha o formulário ao lado e
                  saiba como inserir sua marca nos 11 anos da maior premiação do
                  ecossistema de inovação brasileiro.
                </S.FormText>
              </Col>
              <Col sm={12} lg={6}>
                {formSuccess ? (
                  <S.FormSuccess>
                    <h3>Tudo certo!</h3>
                    <p>
                      Em breve você receberá mais informações no seu e-mail.
                    </p>
                    <NewButton
                      onClick={() =>
                        window.open('/files/BrandKit_2k23.pdf', '_blank')
                      }>
                      Acessar Brand Kit
                    </NewButton>
                  </S.FormSuccess>
                ) : (
                  <S.FormWrapper onSubmit={handleSubmit(handleForm)}>
                    <Controller
                      control={control}
                      name="name"
                      defaultValue=""
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <TextField
                          label="Nome"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          inputRef={ref}
                          placeholder="Digite seu nome"
                          error={!!errors && !!errors.name}
                          errorMessage={
                            !!errors && !!errors.name ? errors.name.message : ''
                          }
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="email"
                      defaultValue=""
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <TextField
                          label="E-mail"
                          placeholder="Digite seu e-mail"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          inputRef={ref}
                          error={!!errors && !!errors.email}
                          errorMessage={
                            !!errors && !!errors.email
                              ? errors.email.message
                              : ''
                          }
                        />
                      )}
                    />
                    <S.WrapperRecaptcha>
                      <ReCAPTCHA
                        sitekey="6Lep34ohAAAAAMK_COExiyLlhIQRmTponouFpzga"
                        onChange={onChangeCaptcha}
                        theme="dark"
                      />
                      {recaptchaError && (
                        <S.RecaptchaError>
                          Por favor confirme que você não é um robô.
                        </S.RecaptchaError>
                      )}
                    </S.WrapperRecaptcha>
                    <NewButton
                      disabled={sending}
                      isLoading={sending}
                      type="submit">
                      Solicitar
                    </NewButton>
                  </S.FormWrapper>
                )}
              </Col>
            </Row>
          </Container>
        </S.Form> */}
      </S.Main>
      <Footer />
      <VideoModal
        videoId="tlLa_ckJcqk"
        open={modalOpen}
        close={() => setModalOpen(false)}
      />
    </>
  )
}

export default Home

export interface CardCategory {
  data: Category
}

const CardCategoria: React.FC<CardCategory> = ({data}) => {
  const {changeCategory, currentStep} = useVotation()
  const {userIsLogged} = useAuth()
  const history = useHistory()

  return (
    <S.CategoriasCard>
      <img src={getImgPath(data.image.path)} alt="" />
      <h3>{data.name}</h3>
      <p>{data.description}</p>
      <NewButton
        onClick={() => {
          changeCategory(data.id)
          if (userIsLogged()) {
            currentStep?.page && history.push(`${currentStep.page}`)
          } else {
            history.push(`/login`)
          }
        }}>
        {currentStep && currentStep.order > 1 ? 'Votar' : 'Indique'}
      </NewButton>
    </S.CategoriasCard>
  )
}
