import styled from 'styled-components'
import colors from '../../styles/colors'

import CloseIcon from '../../assets/images/icon/close'

import bgHistorico from '../../assets/images/bg/bg-historico.jpg'

export const Main = styled.main`
  background: url(${bgHistorico});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: calc(100vh - 70px);
  position: relative;
  padding-top: 70px;
  padding-bottom:20px;
  color: ${colors.white.primary};
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      115.83deg,
      #000000 45.79%,
      rgba(0, 0, 0, 0.52) 92%
    );
  }

  @media (max-width: 600px) {
    padding-top: 20px;
    min-height: calc(100vh - 50px);
  }
`
export const TitleSection = styled.section`
  padding-bottom: 50px;
  position: relative;
  z-index: 1;

  @media (max-width: 600px) {
    padding-bottom: 30px;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 50px;
  line-height: 1.02;
  letter-spacing: -0.03em;
  margin-bottom: 40px;
  max-width: 90%;

  @media (max-width: 1450px) {
    max-width: unset;
  }

  @media (max-width: 992px) {
    font-size: 34px;
    line-height: 1.23;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 1.23;
    letter-spacing: -0.03em;
  }
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.45;
  max-width: 80%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 15px;
    line-height: 1.35;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1.43;
  }
`

export const SliderSection = styled.section`
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    margin-bottom: 25px;
  }

  .swiper {
    padding-bottom: 50px;
    overflow: visible;
  }

  .swiper-pagination {
    text-align: right;
  }

  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: ${colors.gray.light};
    opacity: 1;
    width: 20px;
    border-radius: 4px;
  }

  .swiper-pagination-bullet {
    background-color: ${colors.gray.light};
    opacity: 0.72;
    transition: all 0.32s ease;
  }
`

export const SliderSectionTitle = styled.h2`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.21;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 20px;
`

export const Slide = styled.article`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

export const SlideYear = styled.h2`
  font-weight: 500;
  font-size: 30px;
  line-height: 2;
  letter-spacing: -0.03em;
  display: flex;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  margin-right: 30px;

  @media (max-width: 992px) {
    width: 33%;
    margin-right: 10px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    border: 1px solid ${colors.white.primary};
    margin-left: 10px;
  }
`

export const SlideImage = styled.div`
  width: 50%;
  background-size: cover;
  background-position: center center;
  margin-left: 10px;
  box-sizing: border-box;
  position: relative;
  border-radius: 6px;
  transition: 800ms cubic-bezier(0.03, 0.98, 0.52, 0.99);

  @media (max-width: 992px) {
    width: 67%;
    margin-left: 5px;
  }

  &:after {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

export const Realização = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  position: relative;
  p {
    color: ${colors.gray.fourth};
    font-size: 10px;
    line-height: 1.15;
    margin-bottom: 10px;
    text-transform: uppercase;

    @media (max-width: 600px) {
      margin-top: 20px;
      font-size: 9px;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      padding: 0 20px 10px 15px;
    }

    a {
      text-decoration: none;
      img {
        height: 35px;
        width: auto;

        @media (max-width: 600px) {
          height: 26px;
        }
      }
      &:last-of-type {
        img {
          transform: scale(0.63);
          transform-origin: left center;
          margin-bottom: 4px;
        }
      }
    }
  }
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.32s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background: ${colors.black.primary70};
`

export const Modal = styled.article`
  width: 100%;
  position: relative;
  background: #131313;
  min-height: 70vh;
  max-height: 80vh;
  padding: 60px 50px;
  color: ${colors.white.primary};
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.black.primary};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${colors.copper.primary};
  }

  @media (max-width: 992px) {
    padding: 40px 20px;
    height: 70vh;
  }

  @media (max-width: 600px) {
    padding: 25px 10px;
  }
`

export const XIcon = styled(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  //transform: translateY(-140%);
  transform: scale(0.9);
  cursor: pointer;
  color: ${colors.copper.primary};
`

export const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.25;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (max-width: 992px) {
    font-size: 16px;
  }
`

export const ModalText = styled.p`
  font-size: 16px;
  line-height: 1.45;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (max-width: 992px) {
    font-size: 14px;
  }
`

export const ModalTitleCat = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (max-width: 992px) {
    font-size: 14px;
  }
`

export const ModalTextCat = styled.p`
  font-size: 15px;
  line-height: 1.45;
  box-sizing: border-box;
  margin-bottom: 3px;

  @media (max-width: 992px) {
    font-size: 13px;
  }
`
export const ModalImages = styled.section`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  transition: all 32ms ease;
  grid-gap: 10px;

  @media (max-width: 992px) {
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  &.active {
    grid-template-columns: 1fr;
  }
`
export const ModalImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: zoom-in;
  position: relative;

  &:hover {
    &:after {
      opacity: 0.7;
    }
  }

  &:after {
    content: 'ampliar';
    font-size: 12px;
    font-family: 'Montserrat';
    color: ${colors.white.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.32s ease;
  }

  &.hidden {
    display: none;
  }

  &:nth-child(1) {
    img {
      max-width: 99%;
    }
  }

  &:nth-child(2n) {
    img {
      max-width: 90%;
    }
  }

  &:nth-child(3n) {
    img {
      max-height: 95%;
      max-width: 95%;
    }
  }

  &:nth-child(4n) {
    img {
      max-height: 85%;
    }
  }

  &:nth-child(5n) {
    img {
      max-width: 80%;
    }
  }

  &.active {
    cursor: zoom-out;
    &:after {
      content: '';
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    display: block;
    transition: all 0.4s ease;
  }
`
