import React, {useState} from 'react'

import {Swiper, SwiperSlide} from 'swiper/react'
import {Pagination} from 'swiper'

import {Col, Row} from 'react-grid-system'

import {Container} from '../../styles/globals'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'

import * as S from './styles'

import Realization from '../../components/Realization'

import HistoricoSlideImage from './image'
import historicoItems, {HistoricoItem} from '../../config/historico'

interface ModalProps {
  closeModal: VoidFunction
  modalData: HistoricoItem
}

interface SlideProps {
  year: number | string
  image: string
}

const Historico: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState<HistoricoItem>({
    year: '',
    mainImage: '',
    winners: [''],
  })

  const breakPoints = {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1100: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1650: {
      slidesPerView: 2,
      spaceBetween: 60,
    },
  }

  return (
    <>
      <S.Main>
        <Container>
          <S.TitleSection>
            <Row>
              <Col sm={12} md={6}>
                <S.Title>
                  Nossa galeria das estrelas da inovação
                </S.Title>
                <S.Text>
                  Ao longo da sua história, o Startup Awards premiou e reconheceu centenas de brasileiros e brasileiras que contribuíram de forma contundente para o nosso ecossistema de inovação. Confira os vencedores e vencedoras de todas as edições.
                </S.Text>
              </Col>
            </Row>
          </S.TitleSection>
        </Container>
        <S.SliderSection>
          <Container>
            <S.SliderSectionTitle>Anos</S.SliderSectionTitle>
            <Swiper
              observeParents={true}
              observer={true}
              pagination={{clickable: true}}
              modules={[Pagination]}
              breakpoints={breakPoints}>
              {historicoItems.map((item) => (
                <SwiperSlide
                  key={item.year}
                  onClick={() => {
                    setModalData(item)
                    setModalOpen(true)
                  }}>
                  <Slide year={item.year} image={item.mainImage} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </S.SliderSection>
        <Container>
          <Realization />
        </Container>
      </S.Main>
      {modalOpen && (
        <Modal modalData={modalData} closeModal={() => setModalOpen(false)} />
      )}
    </>
  )
}

export default Historico

const Slide: React.FC<SlideProps> = ({year, image}) => {
  return (
    <>
      <S.Slide>
        <S.SlideYear>{year}</S.SlideYear>
        <HistoricoSlideImage image={image} />
      </S.Slide>
    </>
  )
}

const Modal: React.FC<ModalProps> = ({modalData, closeModal}) => {
  const [activeImage, setActiveImage] = useState<boolean | number | null>(null)

  document.addEventListener(
    'keydown',
    (e) => {
      if (e.keyCode === 27) {
        closeModal && closeModal()
        setActiveImage(false)
      }
    },
    false,
  )

  return (
    <S.Wrapper>
      <Container>
        <S.Modal>
          <S.XIcon onClick={() => closeModal && closeModal()} />
          <Row>
            <Col sm={12} md={6}>
              <S.ModalTitle>{modalData.title}</S.ModalTitle>
              {modalData.text && <S.ModalText>{modalData.text}</S.ModalText>}
              <S.ModalTitleCat>Categoria / Vencedor</S.ModalTitleCat>
              {modalData.winners.map((winner) => (
                <S.ModalTextCat key={winner}>{winner}</S.ModalTextCat>
              ))}
            </Col>
            <Col sm={12} md={6}>
              <S.ModalImages className={`${activeImage ? 'active' : ''}`}>
                {modalData.images?.map((image, i) => (
                  <S.ModalImage
                    onClick={() =>
                      activeImage ? setActiveImage(null) : setActiveImage(i + 1)
                    }
                    key={`image-${i}`}
                    className={`${
                      activeImage && activeImage === i + 1
                        ? 'active'
                        : `${!activeImage ? '' : 'hidden'}`
                    }`}>
                    <img
                      src={image}
                      alt={modalData.title}
                      title={modalData.title}
                    />
                  </S.ModalImage>
                ))}
              </S.ModalImages>
            </Col>
          </Row>
        </S.Modal>
      </Container>
    </S.Wrapper>
  )
}
