import styled from 'styled-components'
import colors from '../../styles/colors'

interface LimitedDivProps {
  first?: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 85vh;
  padding-top: 50px;
`

export const LimitedDiv = styled('div')<LimitedDivProps>`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  color: ${colors.white.primary};
  padding-top: 50px;
`

export const WrapperBtn = styled.div`
  width: 100%;

  button {
    display: block;
    width: 70%;
    margin: 0 auto;
  }
`

export const TxtHeading = styled.p`
  text-align: center;
  font-weight: 600;
  line-height: 1.25;
  font-size: 44px;
  margin-bottom: 16px;
  letter-spacing: -0.02em;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`

export const TxtInfo = styled.p`
  text-align: center;
  font-size: 18px;
  line-height: 1.22;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`

export const Ok = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  font-size: 60px;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 6px;
  color: ${colors.white.primary};
  background: ${colors.copper.primary};
  margin-bottom: 20px;

  @media (max-width: 600px) {
    width: 130px;
    height: 130px;
    font-size: 50px;
  }
`

export const TxtIndications = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 30px;
  color: ${colors.gray.light};

  @media (max-width: 600px) {
    font-size: 12px;
  }

  @media (max-width: 600px) {
    max-width: 80%;
    margin: 0 auto 30px;
    br {
      display: none;
    }
  }
`

export const WrapperRealization = styled.div`
  margin: 80px auto 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`
