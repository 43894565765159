import styled from 'styled-components'
import colors from '../../../styles/colors'

export const PasswordWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
`

export const Icon = styled.div`
  position: absolute;
  top: 21px;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: ${colors.gray.fourth};

  svg {
    width: 100%;
    height: 100%;
    color: inherit;
  }
`
