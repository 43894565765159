import React, {useState, useEffect} from 'react'
import ActionButton from '../Buttons/ActionButton'
import * as S from './styles'
import {useVotation} from '../../context/VotationContext/Context'
import {useAuth} from '../../context/AuthContext/Context'
import CustomModal from '../CustomModal'

const PopUp: React.FC = () => {
  const [open, setOpen] = useState(false)
  const {currentStep} = useVotation()
  const {user} = useAuth()

  useEffect(() => {
    const isAware = localStorage.getItem('confirmeiRegulamento')
    if (!isAware && currentStep?.stage === 'Fase 3' && user?.role !== "Comum") {
      setOpen(true)
    }
    document.addEventListener(
      'keydown',
      (e) => {
        if (e.keyCode === 27) {
          setOpen(false)
        }
      },
      false,
    )
  }, [])

  const handleClick = () => {
    setOpen(false)
    const date = new Date()
    localStorage.setItem('confirmeiRegulamento', date.toString())
  }

  return open ? (
    <CustomModal
      title={"Atenção"}
      btnText={"ESTOU CIENTE"}
      btnAction={()=>{handleClick()}}
      onClose={
        ()=>{setOpen(false)}
      }
    >
      <p>
        Para um voto justo e consciente, leia o{' '}
        <a
          href="/files/regras-da-academia-startup-awards.pdf.pdf"
          target="_blank"
          rel="noopener noreferrer">
          Regulamento da Academia
        </a>{' '}
        e os atributos presentes em cada categoria.
      </p>
    </CustomModal>
  ) : (
    <></>
  )
}

export default PopUp
