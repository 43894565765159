import styled from 'styled-components'
import colors from '../../styles/colors'
import CloseIcon from '../../assets/images/icon/close'

interface LimitedDivProps {
  first?: boolean
}

export const XIcon = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(80%);
  color: ${colors.copper.primary};
  cursor: pointer;
`

export const LimitedDiv = styled('div')<LimitedDivProps>`
  max-width: 1008px;
  width: 90%;
  margin: 0 auto;
  color: ${colors.gray.light};
  ${(props) => (props.first ? `margin-top: 60px` : ``)};
  position: relative;
`

export const TxtHeading = styled.p`
  line-height: 1.3;
  font-size: 14px;
  letter-spacing: 4.2px;
  text-transform: uppercase;
  margin-bottom: 60px;
`
export const Wrapper = styled.div`
  height: 50vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 16px;
  margin-bottom: 30px;

  @media (max-width: 1700px) {
    height: 45vh;
  }

  &::-webkit-scrollbar {
    width: 22px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.black.primary};
    border: 10px solid transparent;
    background-clip: content-box;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    border-radius: 10px;
    background: ${colors.copper.primary};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
  }
`
export const Content = styled.div`
  min-height: 100%;
  width: 100%;
  padding-bottom: 20px;
  background: ${colors.black.primary};
  border: 1px solid ${colors.gray.second};
  border-radius: 10px;
  padding: 44px;
  box-sizing: border-box;
  margin-right: 10px;
  overflow: visible;
  font-size: 18px;
  line-height: 1.5;
  color: ${colors.gray.light};
  white-space: pre-line;
  font-family: 'Montserrat';

  @media (max-width: 600px) {
    padding: 30px 20px;
  }
`

export const WrapperRealization = styled.div`
  margin: 50px auto 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`
