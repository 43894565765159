import React, {ButtonHTMLAttributes} from 'react'
import * as S from './styles'
import buttonLoader from '../../../assets/images/buttonLoader.svg'

interface NewButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  isChecked?: boolean
  outline?: boolean
}

const NewButton: React.FC<NewButton> = ({children, isLoading, className, outline = false, ...props}) => {
  return (
    <S.Button className={`${className} ${outline ? "outline" : ''}`} {...props}>
      {isLoading ? <img src={buttonLoader} /> : children}
    </S.Button>
  )
}

export default NewButton
