import axios from 'axios';
import {CATEGORIES} from './constants';
import {handleError} from './utils';

const categoriesService = {
  getWithVotesAndPartner: params => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          CATEGORIES.GET_CATEGORIES_WITH_PARTNER_AND_VOTES(
            params.userId,
            params.order,
            params.stageId,
          ),
          {
            headers: {
              'x-access-token': params.token,
            },
          },
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
};

export default categoriesService;
