import styled from 'styled-components'
import colors from '../../styles/colors'

interface LimitedDivProps {
  first?: boolean
}

export const LimitedDiv = styled('div')<LimitedDivProps>`
  max-width: 1008px;
  width: 90%;
  margin: 0 auto;
  color: ${colors.white.primary};
  ${(props) => (props.first ? `margin-top: 60px` : ``)};
  position: relative;
  text-align: center;

  button {
    display: block;
    max-width: 350px;
    margin: 2rem auto 2rem;
  }
`

export const TxtHeading = styled.h1`
  line-height: 1.3;
  font-size: 24px;
  letter-spacing: 4.2px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
`

export const TxtSub = styled.p`
  line-height: 1.5;
  font-size: 16px;
  letter-spacing: 4.2px;
  // text-transform: uppercase;
  //margin-bottom: 60px;
`

export const TxtH2 = styled.h2`
  line-height: 1.3;
  font-size: 22px;
  letter-spacing: 4.2px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 30px;
`

export const TxtInd = styled.p`
  line-height: 1.3;
  font-size: 14px;
  letter-spacing: 4.2px;
  text-transform: uppercase;
  font-weight: medium;
  margin-bottom: 30px;
`

export const Content = styled.div`
  min-height: 100%;
  width: 100%;
  padding-bottom: 20px;
  background: ${colors.black.primary};
  border: 1px solid ${colors.gray.second};
  border-radius: 10px;
  padding: 44px;
  box-sizing: border-box;
  margin-right: 10px;
  overflow: visible;
  font-size: 18px;
  line-height: 1.5;
  color: ${colors.gray.light};
  white-space: pre-line;
  font-family: 'Montserrat';

  @media (max-width: 600px) {
    padding: 30px 20px;
  }
`

export const WrapperRealization = styled.div`
  margin: 35px auto 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    margin-top: 10px;
    max-height: 30px;
    width: auto;
    &.blanko {
      max-height: 24px;
      padding-bottom: 6px;
    }
  }

  div {
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;
`
