import React from 'react'
import * as S from './styles'

import AbsLogo from '../../assets/images/logos/abs-logotipo-negativo.svg'

const Realization: React.FC = () => {
  return (
    <S.WrapperRealization>
      <S.TxtRealization>REALIZAÇÃO</S.TxtRealization>
      <div>
        <img src={AbsLogo} alt="Abstartups" title="Abstartups" />
      </div>
    </S.WrapperRealization>
  )
}

export default Realization
