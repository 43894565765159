import styled from 'styled-components'

import bgVideo from '../../assets/images/bg-2k24-image.jpg'
import dobraXBg from '../../assets/images/bg/dobrax/2024.jpg'

import colors from '../../styles/colors'
import PlayIcon from '../../assets/images/icon/play'
import {Container} from '../../styles/globals'

export const ContainerRelative = styled(Container)`
  position: relative;
  z-index: 2;

  @media (max-width: 600px) {
    min-height: 100%;
  }
`

export const Main = styled.section`
  background-color: ${colors.black.secondary};
  background-image: url(${bgVideo});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 178px;
  color: ${colors.white.primary};
`

export const Topo = styled.section`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 106px;
  position: relative;
  // background: url(${bgVideo});
  background-size: cover;
  overflow: hidden;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: ${colors.black.primary};
  //   opacity: 0.78;
  //   z-index: 1;
  // }

  @media (max-width: 600px) {
    height: auto;
    min-height: 300px;
    padding: 60px 0;
  }
`

export const TopoVideoBg = styled.video`
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 0;
  transform: translateY(-50%);

  @media (max-width: 992px) {
    height: 100%;
    width: auto;
  }
`

export const TopoTitle = styled.h1`
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 112.5%;
  letter-spacing: 1.5px;
  margin-bottom: 40px;
  max-width: 100%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 34px;
    line-height: 1.23;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 1.23;
    letter-spacing: -0.03em;
    margin-top: 15px;
    margin-bottom: 20px;
  }
`

export const TopoText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.23;
  margin-bottom: 40px;
  max-width: 68%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 15px;
    line-height: 1.35;
    margin-bottom: 21px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1.43;
    margin-bottom: 30px;
  }
`

export const TopoPlay = styled(PlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  @media (max-width: 760px) and (orientation: landscape) and (min-width: 601px) {
    transform: translate(-50%, -110%);
  }

  @media (max-width: 600px) {
    position: relative;
    margin: 128px auto;
    top: unset;
  }
`
export const Timeline = styled.section`
  width: 100%;
  padding-top: 80px;
  padding-bottom: 50px;
`

export const TimelineTitle = styled.h2`
  font-weight: 400;
  font-size: 40px;
  line-height: 112.5%;
  letter-spacing: 1.5px;
  margin-bottom: 30px;

  max-width: 80%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 34px;
    line-height: 1.23;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 1.23;
    letter-spacing: -0.03em;
  }
`

export const TimelineText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.23;
  margin-bottom: 12px;

  max-width: 436px;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 15px;
    line-height: 1.35;
    margin-bottom: 31px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1.43;
  }
`
export const TimelineSubText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.23;
  margin-bottom: 50px;

  max-width: 436px;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 13px;
    margin-bottom: 31px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

export const TimelineSup = styled.span`
  display: block;
  font-weight: 200;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.12em;
  margin-bottom: 50px;
  text-transform: uppercase;
  margin-left: 16%;

  @media (max-width: 992px) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

export const TimelineItem = styled.article`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 60px 3fr;

  &.passed {
    h3,
    p {
      opacity: 0.6;
    }

    button {
      opacity: 1;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 40px 3fr;
  }

  &:last-child {
    div:last-of-type {
      padding-bottom: 0;
    }
  }
`

export const TimelineItemDate = styled.p`
  text-align: right;
  font-weight: 400;
  font-size: 20px;
  line-height: 111%;

  @media (max-width: 600px) {
    word-break: break-all;
  }
`

export const TimelineItemLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: ${colors.copper.primary};
    width: 1px;
    height: 100%;
  }

  div {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    background: ${colors.copper.primary};
  }
`

export const TimelineItemContent = styled.div`
  padding-bottom: 57px;

  h3 {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 11px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 114.286%;
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    h3,
    p {
      font-size: 14px;
      line-height: 1.43;
      margin-bottom: 15px;
    }
  }
`

export const Categorias = styled.section`
  width: 100%;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 72px;

  @media (max-width: 992px) {
    padding-bottom: 37px;
  }
`

export const CategoriasSup = styled.span`
  display: block;
  font-weight: 200;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.12em;
  margin-bottom: 16px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`

export const CategoriasTitle = styled.h2`
  font-weight: 400;
  font-size: 40px;
  line-height: 112.5%;
  letter-spacing: 1.5px;
  margin-bottom: 28px;

  max-width: 80%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 34px;
    line-height: 1.23;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 1.23;
    letter-spacing: -0.03em;
  }
`

export const CategoriasText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.23;
  margin-bottom: 40px;

  max-width: 80%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 15px;
    line-height: 1.35;
    margin-bottom: 30px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1.43;
  }
`

export const CategoriasSlideWrapper = styled.div`
  .swiper {
    padding-bottom: 50px;

    @media (max-width: 992px) {
      overflow: visible;
      .swiper-slide {
        height: auto;
      }
    }
  }

  .swiper-pagination {
    text-align: right;
  }

  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: ${colors.copper.primary};
    opacity: 1;
    width: 20px;
    border-radius: 4px;
  }

  .swiper-pagination-bullet {
    background-color: ${colors.copper.primary};
    opacity: 0.72;
    transition: all 0.32s ease;
  }
`

export const CategoriasCard = styled.article`
  padding: 34px 24px;
  position: relative;
  width: 312px;
  height: 349px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  border-radius: 4px;
  border: 1px solid #3C3C3C;
  background: #000;

  &:hover {
    img {
      top: 80px;
      width: 118px;
      height: 72px;
      transform: translate(-50%, 0);
      opacity: 0.98;
    }
    p {
      opacity: 1;
      transform: translateY(20px);
    }
  }

  @media (max-width: 1900px) {
    width: auto;
    // height: 349px;
  }

  @media (max-width: 992px) {
    // height: auto;
    width: auto;
    padding: 24px;
    // min-height: 85%;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 121px;
    max-width: 90%;
    object-fit: contain;
    object-position: center;
    opacity: 1;
    transition: all 0.32s ease;
  }

  h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 111.111%;
    margin-bottom: 46px;
    position: relative;

    @media (max-width: 992px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 20px;
    position: relative;
    opacity: 0;
    transition: 0.32s ease;
    transform: translateY(100%);

    @media (max-width: 992px) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
`

export const DobraX = styled.section`
  width: 100%;
  background-image: url(${dobraXBg});
  background-size: cover;
  background-position: center top;
  padding: 55px 0;
  min-height: 530px;

  @media (max-width: 992px) {
    background-image: unset;
    background: ${colors.black.primary};
  }

  img {
    width: 80%;
    height: auto;
    margin: 20px auto 0;
    display: none;
    opacity: 0.9;

    @media (max-width: 992px) {
      display: block;
      width: 100%;
    }
  }
`

export const DobraXTitle = styled.h2`
  font-weight: 400;
  font-size: 40px;
  line-height: 112.5%;
  letter-spacing: 1.5px;
  margin-bottom: 16px;

  max-width: 85%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 34px;
    line-height: 1.23;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 1.23;
    letter-spacing: -0.03em;
  }
`

export const DobraXText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.23;
  margin-bottom: 40px;

  max-width: 530px;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 15px;
    line-height: 1.35;
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1.43;
  }
`

export const Form = styled.section`
  width: 100%;
  padding-top: 120px;
  padding-bottom: 60px;
`

export const FormTitle = styled.h2`
  font-weight: 600;
  font-size: 50px;
  line-height: 1.02;
  letter-spacing: -0.03em;
  margin-bottom: 40px;

  max-width: 80%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 34px;
    line-height: 1.23;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 1.23;
    letter-spacing: -0.03em;
  }
`

export const FormText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.23;
  margin-bottom: 40px;

  max-width: 80%;

  @media (max-width: 1450px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: unset;
    font-size: 15px;
    line-height: 1.35;
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1.43;
  }
`
export const FormWrapper = styled.form`
  width: 80%;

  @media (max-width: 992px) {
    width: 100%;
  }
`

export const WrapperRecaptcha = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
`

export const RecaptchaError = styled.p`
  color: ${colors.copper.primary};
  margin-top: 10px;
  font-weight: 500;
`

export const FormSuccess = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 50px;

  h3 {
    font-weight: 600;
    font-size: 50px;
    line-height: 1.02;
    letter-spacing: -0.03em;
    margin-bottom: 40px;

    max-width: 80%;

    @media (max-width: 1450px) {
      max-width: 90%;
    }

    @media (max-width: 992px) {
      max-width: unset;
      font-size: 34px;
      line-height: 1.23;
      letter-spacing: -0.03em;
      margin-bottom: 10px;
    }

    @media (max-width: 600px) {
      font-size: 26px;
      line-height: 1.23;
      letter-spacing: -0.03em;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.23;
    margin-bottom: 40px;

    max-width: 80%;

    @media (max-width: 1450px) {
      max-width: 90%;
    }

    @media (max-width: 992px) {
      max-width: unset;
      font-size: 15px;
      line-height: 1.35;
      margin-bottom: 20px;
    }

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 1.43;
    }
  }

  button {
    display: inline-block;
  }
`
// 2024

export const ColorLogo = styled.div`
  display: flex;
  position: relative;
  width: 367px;
  height: auto;
  max-width: 100%;
  margin-bottom: 40px;

  & img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left top;
  }
`
