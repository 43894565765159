// import {toast} from 'react-toastify'

export const handleError = async (error) => {
  console.log('-')
  if (error.status === 401 || error.status === 503) {
    localStorage.clear()
    window.location.href = '/login'
  }
}

export const handlerAppIsBlock = async (error, callback) => {
  if (error.status === 503) {
    callback()
  }
}
