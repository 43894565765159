import styled from 'styled-components'
import colors from '../../../styles/colors'

export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: ${colors.gray.light};
  text-align: center;
`
