import axios from 'axios'
import {SITE} from './constants'
import {handleError} from './utils'

const siteService = {
  getCategories: () => {
    return new Promise((resolve, reject) => {
      axios
        .get(SITE.GET_CATEGORIES)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          handleError(error.response)
          reject(error.response)
        })
    })
  },
}

export default siteService
