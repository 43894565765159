import styled from 'styled-components'
import colors from '../../../styles/colors'

interface Select {
  fullWidth?: boolean
  error?: boolean
}

export const SelectField = styled('select')<Select>`
  background: transparent;
  border: ${(props) =>
    props.error
      ? `1px solid ${colors.red.primary}`
      : `1px solid ${colors.copper.primary}`};
  width: 100%;
  height: 42px;
  border-radius: 4px;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: ${colors.gray.light};
  padding: 8px 10px 2px;
  outline: 0;
  box-sizing: border-box;

  &::placeholder {
    font-size: 12px;
    color: ${colors.gray.fourth};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #141414 inset;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #ffffff !important;
  }

  &:focus {
    outline: none !important;
  }

  option {
    background: ${colors.black.primary};
  }
`

export const WrapperField = styled.div`
  display: block;
  position: relative;
  margin: 10px auto 20px;

  & > label {
    display: block;
    font-family: 'Montserrat';
    color: ${colors.gray.fourth};
    display: block;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 12px;
    transform: translateY(-50%);
    background: ${colors.black.primary};
    padding: 0 2px;
  }
`

export const ErrorMessage = styled.fieldset`
  font-family: 'Montserrat';
  padding: 8px 0 0 0;
  font-size: 13px;
  color: ${colors.white.primary};
  //position: absolute;
`
