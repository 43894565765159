import ReactGA4 from "react-ga4";

const GA_MEASUREMENT_ID = "G-DRX5S45QPY";

function init() {
  ReactGA4.initialize(GA_MEASUREMENT_ID);
}

function sendEvent(payload) {
  ReactGA4.event(payload);
}

function sendPageview(path) {
  ReactGA4.set({ page: path });
  ReactGA4.send({ hitType: "pageview", page: path });
}

export default {
  init,
  sendEvent,
  sendPageview,
};
