import React, {ButtonHTMLAttributes} from 'react'
import * as S from './styles'
import buttonLoader from '../../../assets/images/buttonLoader.svg'

interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

const ActionButton: React.FC<ActionButtonProps> = ({
  children,
  isLoading,
  ...props
}) => {
  return (
    <S.Button {...props}>
      {isLoading ? <img src={buttonLoader} /> : children}
    </S.Button>
  )
}

export default ActionButton
