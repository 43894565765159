import React, {InputHTMLAttributes, Ref} from 'react'
import * as S from './styles'

export interface SwitchFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  name?: string
  error?: boolean
  inputRef?: Ref<HTMLInputElement> | null | undefined
}

const SwitchField: React.FC<SwitchFieldProps> = (props) => {
  return (
    <S.WrapperField>
      <S.WrapperCheck>
        <S.Checkbox type="checkbox" {...props} ref={props.inputRef} />
        <S.Switch />
      </S.WrapperCheck>
      {props.label && <span>{props.label}</span>}
    </S.WrapperField>
  )
}

export default SwitchField
