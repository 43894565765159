import React from 'react'

import * as S from './styles'

import {Container} from '../../styles/globals'
import {useAuth} from '../../context/AuthContext/Context'

import StartupAwards from '../../assets/images/logos/header-logo-2K24.svg'
import UserIcon from '../../assets/images/icon/user-icon.svg'

import TopWarning from '../TopWarning'

const Header: React.FC = () => {
  const {userIsLogged, user, toggleMenu} = useAuth()

  return (
    <S.Header>
      <TopWarning />
      <Container>
        <S.HeaderWrapper>
          <a href="/" title="Startup Awards">
            <S.StartupAwardsLogo src={StartupAwards} title="Startup Awards" />
          </a>
          <S.Menu onClick={() => toggleMenu()}>
            <S.LoginName>
              <img src={UserIcon} title="" alt="" />
              <span>{userIsLogged() ? user?.name : 'Login'}</span>
            </S.LoginName>
            <S.MenuIcon />
          </S.Menu>
        </S.HeaderWrapper>
      </Container>
    </S.Header>
  )
}

export default Header
