import axios from 'axios'
import {handleError} from './utils'
import {TERMS} from './constants'

const getTermByType = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(TERMS.GET_TYPE(params.type))
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        handleError(error)
        reject(error)
      })
  })
}

export {getTermByType}
