import styled from 'styled-components'
import colors from '../../../styles/colors'

interface Wrapper {
  error?: boolean
}

export const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

export const Switch = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.gray.second};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    border-radius: 50%;
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: ${colors.white.primary};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 0.65em;
    line-height: 0.5;
    color: ${colors.gray.second};
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const WrapperCheck = styled('div')<Wrapper>`
  width: 60px;
  height: 34px;
  position: relative;
`

export const WrapperField = styled('label')<Wrapper>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: auto;

  input:checked + div {
    background-color: ${colors.gray.second};
    &:before {
      background-color: ${colors.copper.primary};
      content: 'OK';
    }
  }

  input:focus + div {
    box-shadow: 0 0 1px ${colors.gray.second};
  }

  input:checked + div:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  & > span {
    color: ${colors.gray.light};
    margin-bottom: 5px;
    line-height: 1.5;
    padding-left: 25px;
    width: 80%;
    display: block;
    font-size: 11px;
    letter-spacing: 0.09px;
    font-weight: 600;
  }
`
