import axios from 'axios';
import {USER_API} from './constants';
import {handleError} from './utils';

const userService = {
  getById: params => {
    return new Promise((resolve, reject) => {
      axios
        .get(USER_API.GET_ID(params.id), {
          headers: {
            'x-access-token': params.token,
          },
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
  put: params => {
    return new Promise((resolve, reject) => {
      axios
        .put(USER_API.PUT, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
  putTerms: params => {
    return new Promise((resolve, reject) => {
      axios
        .put(USER_API.PUT_TERMS, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
  putAllTerms: params => {
    return new Promise((resolve, reject) => {
      axios
        .put(USER_API.PUT_ALL_TERMS, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
  delete: params => {
    return new Promise((resolve, reject) => {
      axios
        .delete(USER_API.DELETE, {
          data: params,
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
  login: params => {
    return new Promise((resolve, reject) => {
      axios
        .post(USER_API.LOGIN, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
  forgotPassword: params => {
    return new Promise((resolve, reject) => {
      axios
        .post(USER_API.FORGOT_PASSWORD, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
  changePassword: params => {
    return new Promise((resolve, reject) => {
      axios
        .put(USER_API.CHANGE_PASSWORD, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          handleError(error.response);
          reject(error.response);
        });
    });
  },
  postUser: params => {
    return new Promise((resolve, reject) => {
      axios
        .post(USER_API.POST, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          // handleError(error);
          // console.log(error);
          reject(error);
        });
    });
  },
  putUser: params => {
    return new Promise((resolve, reject) => {
      axios
        .put(USER_API.PUT, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          // handleError(error);
          reject(error);
        });
    });
  },
  loginApp: params => {
    return new Promise((resolve, reject) => {
      axios
        .post(USER_API.LOGIN_APP, params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          // handleError(error);
          reject(error);
        });
    });
  },
  deleteUser: params => {
    return new Promise((resolve, reject) => {
      axios
        .delete(USER_API.DELETE, {
          data: params,
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          // handleError(error);
          reject(error);
        });
    });
  },
};

export default userService;
