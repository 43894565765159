import styled from 'styled-components'
import colors from '../../styles/colors'
import CloseIcon from '../../assets/images/icon/close'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: ease .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background: ${colors.black.secondary80};
`

export const Modal = styled.div`
  width: 80%;
  max-width: 384px;
  height: auto;
  max-height: 436px;
  overflow-y: auto;
  position: relative;
  background: ${colors.black.secondary};
  border: 1px solid ${colors.gray.third};
  border-radius: 4px;
  padding: 66px 23px 44px;
`

export const Body = styled.div`
  color: ${colors.gray.light};
  font-size: 12px;
  font-weight: 400;
  line-height: 166.667%;

  & p + p{
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid ${colors.gray.hard};
  }

  & a{
    color: ${colors.copper.primary};
  }
`

export const Title = styled.p`
  color: ${colors.gray.fourth};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 171.429%;
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 9px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${colors.gray.hard}
`

export const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid ${colors.gray.hard}
`
export const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  cursor: pointer;
  color: ${colors.copper.primary};
  transition: ease .2s;

  & svg{
    font-size: 26px;
    color: inherit;
  }

  &:hover{
    color: ${colors.red.primary}
  }
`

export const TypeIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -20px auto 10px;

  & svg{
    font-size: 48px;
    color: #fff;
  }

  & .error{
    color: ${colors.red.primary};
  }
  & .success{
    color: ${colors.green.primary};
  }
`
