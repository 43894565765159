import styled from 'styled-components'
import colors from '../../styles/colors'

import footerBg from '../../assets/images/bg/bg-footer.png'

export const Footer = styled.footer`
  background-color: ${colors.black.secondary};
  color: ${colors.white.primary};
  padding: 86px 0 58px;
  font-family: 'Montserrat', sans-serif;
  background-image: url(${footerBg});
  background-size: 100% auto;
  background-position: 100% 150%;
  background-repeat: no-repeat;
`

export const MainFooter = styled.footer`
  img {
    width: 185px;
    height: auto;
    margin-bottom: 38px;

    @media (max-width: 600px) {
      width: 100px;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      font-weight: 400;
      font-size: 22px;
      line-height: 1.27;
      margin-bottom: 20px;
      color: #fff;
      text-decoration: none !important;
      transition: ease .2s;

      &:hover{
        color: ${colors.yellow.primary};
      }

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
`

export const SubFooter = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Realização = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding-bottom: 37px;
  p {
    color: ${colors.gray.fourth};
    font-size: 10px;
    line-height: 1.15;
    margin-bottom: 13px;
    text-transform: uppercase;

    @media (max-width: 600px) {
      margin-top: 20px;
      font-size: 9px;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      padding: 0 20px 10px 15px;
    }

    a {
      text-decoration: none;
      img {
        display: block;
        height: 35px;
        width: auto;

        @media (max-width: 600px) {
          height: 26px;
        }
      }
      // &:last-of-type {
      //   img {
      //     transform: scale(0.63);
      //     transform-origin: left center;
      //     margin-bottom: 4px;
      //   }
      // }
    }
  }
`

export const Siganos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: ${colors.gray.fourth};
    font-size: 10px;
    font-weight: 400;
    line-height: 161%;
    letter-spacing: 0.98px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  div {
    display: flex;
    a {
      display: block
      text-decoration: none !important;
      margin: 0 5px;
      height: 24px;
      width: 24px;
      color: #fff;
      transition: ease .2s;

      &:hover{
        color: ${colors.yellow.primary};
      }
    }

    // img {
    //   width: 24px;
    //   height: auto;
    // }

    svg {
      display: block
      height: 100%;
      width: 100%;
      font-size: 24px;
      color: inherit;
    }
  }
`
