import React from 'react'

import OutlinedButton from '../../components/Buttons/OutlinedButton'

import {Col, Container, Row} from 'react-grid-system'

import {useHistory} from 'react-router'

import * as S from './styles'
import Footer from '../../components/Footer'

const Vencedores: React.FC = () => {
  const history = useHistory()

  const vencedores = [
    {
      categoria: 'Em breve',
      indicados: []
    },
    // {
    //   categoria: 'Mentor ou Mentora do Ano',
    //   indicados: [
    //     'Renata Luz',
    //   ],
    // },
    // {
    //   categoria: 'Venture Capital do Ano',
    //   indicados: [
    //     'ACE Ventures',
    //   ],
    // },
    // {
    //   categoria: 'Corporate do Ano',
    //   indicados: [
    //     'Natura',
    //   ],
    // },
    // {
    //   categoria: 'Herói ou Heroína do Ano',
    //   indicados: [
    //     'Vanessa Pessoa',
    //   ],
    // },
    // {
    //   categoria: 'Agentes de Fomento do Ano',
    //   indicados: [
    //     'SEBRAE',
    //   ],
    // },
    // {
    //   categoria: 'Instituição de Crescimento do Ano',
    //   indicados: [
    //     'SEBRAE',
    //   ],
    // },
    // {
    //   categoria: 'Investidor ou Investidora Anjo do Ano',
    //   indicados: [
    //     'Camila Farani',
    //   ],
    // },
    // {
    //   categoria: 'Iniciativa de Educação do Ano',
    //   indicados: [
    //     'Alura',
    //   ],
    // },
    // {
    //   categoria: 'Hub de Tecnologia do Ano',
    //   indicados: [
    //     'Cubo',
    //   ],
    // },
    // {
    //   categoria: 'Profissional de Imprensa do Ano',
    //   indicados: [
    //     'Beatriz Bevilaqua',
    //   ],
    // },
    // {
    //   categoria: 'Startup Revelação do Ano',
    //   indicados: [
    //     'Blindog',
    //   ],
    // },
    // {
    //   categoria: 'Startup do Ano',
    //   indicados: [
    //     'Exact Sales',
    //   ],
    // },
    // {
    //   categoria: 'Comunidade Revelação do Ano',
    //   indicados: [
    //     'Açaí Valley',
    //   ],
    // },
    // {
    //   categoria: 'Comunidade do Ano',
    //   indicados: [
    //     'Rapadura Valley',
    //   ],
    // },

  ]

  return (
    <>
      <Container>
        <Row>
          <Col sm={12}>
            <S.LimitedDiv first>
              <S.TxtHeading>
                Confira aqui os vencedores de cada categoria!
              </S.TxtHeading>
              <S.TxtSub>
                A Academia Awards entrou em ação e escolheu os heróis de cada
                categoria do Startup Awards 2k24. Confira os vencedores da nossa
                premiação.
              </S.TxtSub>
            </S.LimitedDiv>
          </Col>
          <Col sm={12}>
            <S.LimitedDiv>
              <Row>
                {vencedores.map((cat, i) => (
                  <Col key={`category-${cat.categoria}-${i}`} sm={12}>
                    <S.TxtH2>{cat.categoria}</S.TxtH2>
                    {cat.indicados.map((ind) => (
                      <S.TxtInd key={`indic-${cat.categoria}-${ind}`}>
                        {ind}
                      </S.TxtInd>
                    ))}
                  </Col>
                ))}
              </Row>

              <OutlinedButton onClick={() => history.push('/')}>
                VOLTAR AO INÍCIO
              </OutlinedButton>
            </S.LimitedDiv>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Vencedores
