import styled from 'styled-components'
import colors from '../../styles/colors'
import CloseIcon from '../../assets/images/icon/close'

interface CriterionCategoryProps {
  criterionIsOpen: boolean
}

interface ModalCriterion extends CriterionCategoryProps {
  positionTop?: string
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 85vh;
  padding-top: 50px;
`

export const WrapperGiveBack = styled.div`
  text-align: center;
  margin-bottom: 30px;
  display: none;

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: ${colors.gray.light};
  }

  img {
    height: 35px;
    width: auto;
  }
`

export const WrapperPage = styled.main`
  position: relative;
  overflow-x: clip;
  overflow-y: visible;
  min-height: 80vh;
`

export const MaskImage = styled.div`
  width: 250px;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;

  & img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
`

export const WrapperCategory = styled.div<CriterionCategoryProps>`
  padding-top: 30px;
  width: 100%;
  // min-height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //opacity: ${({criterionIsOpen}) => (criterionIsOpen ? '0' : '1')};
  transition: 0.2s;

  @media (max-width: 600px) {
    height: auto;
  }

  & img {
    height: 200px;
  }
`

export const WrapperInfoCategory = styled.div`
  margin-top: 0px;
  max-width: 400px;
  width: 100%;
  text-align: center;
`

export const TitleCategory = styled.h3`
  letter-spacing: 2px;
  line-height: 1.1;
  color: ${colors.white.primary};
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  display: block;
  margin-bottom: 20px;
`

export const DescriptionCategory = styled.p`
  color: ${colors.gray.light};
  font-size: 14px;
  line-height: 1.45;
  display: block;
  text-align: center;
  margin-bottom: 30px;
`

export const WrapperCriterionBtn = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 40px;
`

export const WrapperAbsCriterionBtn = styled.div<CriterionCategoryProps>`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  button {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
`

export const ModalCriterion = styled.div<ModalCriterion>`
  z-index: 1;
  background: ${colors.black.primary};
  width: 80%;
  max-width: 400px;
  height: ${({criterionIsOpen}) => (criterionIsOpen ? 'fit-content' : '0')};
  padding: 0px 29px 20px;
  border-radius: 4px;
  border: 1px solid ${colors.gray.third};
  overflow: hidden;
  position: fixed;
  top: 50%;
  opacity: ${({criterionIsOpen}) => (criterionIsOpen ? '1' : '0')};
  transform: translate(-50%, -50%);
  left: 50%;
  transition: 0.5s;
  pointer-events: ${({criterionIsOpen}) => (criterionIsOpen ? 'all' : 'none')};

  @media (max-width: 600px) {
    top: -20vh;
  }
`

export const ModalCriterionHead = styled.div`
  padding-top: 50px;
  padding-bottom: 15px;
  p {
    color: ${colors.gray.fourth};
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }
`

export const XIcon = styled(CloseIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  color: ${colors.copper.primary};
  cursor: pointer;
  height: 1em;
  width: 1em;
`

export const WrapperCriterion = styled.div`
  padding: 30px 0px 20px;
  border-top: 1px solid ${colors.gray.hard};
  height: fit-content;
`

export const Criterion = styled.p`
  font-size: 12px;
  line-height: 1.67;
  color: ${colors.gray.light};
  max-width: 90%;
  margin: 0 auto;
`

export const FieldRow = styled.div`
  width: 100%;
  margin: 0px auto 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    display: block;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  & .action{
    height: 40px;
    width: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    border-radius: 4px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

`

export const WrapperField = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 10px;

  & > div{
    margin: 0;
  }

  @media (max-width: 600px) {
    margin: 0 0 15px;
  }
`

export const WrapperPagination = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 20px auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .action {
    min-width: 70%;
  }
`

export const AbsMenuWrapper = styled.div`
  position: absolute;
  left: 50px;
  width: fit-content;

  @media (max-width: 992px) {
    left: 30px;
  }
`

export const PaginationButton = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: transparent;
  border: 2px solid ${colors.copper.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const WrapperRealization = styled.div`
  margin: 80px auto 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`
