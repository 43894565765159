import styled from 'styled-components'
import colors from '../../styles/colors'
import Hamburger from '../../assets/images/icon/hamburger'

export const Header = styled.header`
  width: 100%;
  background: ${colors.gradient.new};
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  color: ${colors.white.primary};
  border-bottom: 1px solid ${colors.gray.third};
`

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;

  @media (max-width: 600px) {
    height: 50px;
  }

  a {
    display: block;
    height: 50%;
    min-height: 36px;
  }
`

export const StartupAwardsLogo = styled.img`
  position: relative;
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (max-width: 992px) {
    max-width: 80%;
  }
`
export const Menu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const MenuIcon = styled(Hamburger)`
  margin-left: 10px;
`

export const LoginName = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;

  @media (max-width: 992px) {
    font-size: 12px;
  }

  img {
    margin-right: 5px;
    height: 1.75em;
    width: 1.75em;
    min-height: 1.75em;
    min-width: 1.75em;
  }

  span {
    letter-spacing: 0.02em;
  }
`
