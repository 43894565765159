import React, {InputHTMLAttributes} from 'react'
import * as S from './styled'

export interface IndicationFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  isChecked?: boolean
}

const IndicationField: React.FC<IndicationFieldProps> = (props) => {
  return (
    <S.Wrapper>
      {props.label && <label>{props.label}</label>}
      <S.Input {...props} disabled={props.isChecked || props.disabled} />
    </S.Wrapper>
  )
}

export default IndicationField
