import styled from 'styled-components'
import colors from '../../../styles/colors'
import {Button as BaseButton} from '../NewButton/styles'

interface OutlinedButton {
  isGray?: boolean
}

export const Button = styled(BaseButton)<OutlinedButton>`
  border: 1px solid
    ${(props) =>
      props.isGray ? `${colors.gray.second}` : `${colors.copper.primary}`};
  background-color: transparent;
  border-radius: 4px;
  color: ${(props) =>
    props.isGray ? `${colors.gray.light}` : `${colors.white.primary}`}; ;
`
