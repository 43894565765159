import React from 'react'

import OutlinedButton from '../../components/Buttons/OutlinedButton'

import {Col, Container, Row} from 'react-grid-system'

import {useHistory} from 'react-router'

import * as S from './styles'
import Footer from '../../components/Footer'

const Indicados: React.FC = () => {
  const history = useHistory()

  const indicados10 = [
    {
      categoria: 'Em breve',
      indicados: []
    },
    // {
    //   categoria: 'Mentor ou Mentora do Ano',
    //   indicados: [
    //     'Ale Uehara',
    //     'Anderson Diehl',
    //     'Edson Rigonatti',
    //     'Fabiano Nagamatsu',
    //     'Felipe Lima da Costa',
    //     'Grazi Gomes',
    //     'Isadora Azzalin',
    //     'Ivy Cristiny',
    //     'Laura Gurgel',
    //     'Leandro Piazza',
    //     'Luciana Homrich',
    //     'Marco Maia',
    //     'Monaliza Medeiros',
    //     'Morganna Tito',
    //     'Paulo Veras',
    //     'Rafael Assunção',
    //     'Renata Luz',
    //     'Theo Orosco',
    //     'Vinícius Machado'
    //   ],
    // },
    // {
    //   categoria: 'Venture Capital do Ano',
    //   indicados: [
    //     'ACE Ventures',
    //     'Agroven',
    //     'Anjos do Brasil',
    //     'Astella Investimentos',
    //     'Bossa Invest',
    //     'BR Angels',
    //     'Domo Invest',
    //     'FCJ',
    //     'Investidores.vc',
    //     'Invisto'
    //   ],
    // },
    // {
    //   categoria: 'Corporate do Ano',
    //   indicados: [
    //     'Algar',
    //     'Ambev',
    //     'ArcelorMittal',
    //     'Banco BV',
    //     'Google',
    //     'Itaú',
    //     'Natura',
    //     'Nestlé',
    //     'Softplan',
    //     'Suzano'
    //   ],
    // },
    // {
    //   categoria: 'Herói ou Heroína do Ano',
    //   indicados: [
    //     'Alexandre Souza',
    //     'Alexandre Uehara',
    //     'Ana Fontes',
    //     'Dani Junco',
    //     'Ferdinando Kun',
    //     'Fernanda Caloi',
    //     'Isadora Azzalin',
    //     'Ivy Cristiny',
    //     'Johnatan Highlander',
    //     'Maria Rita Spina Bueno',
    //     'Mário Alves',
    //     'Michel Porcino',
    //     'Monnaliza Medeiros',
    //     'Olivia Boretti',
    //     'Paula Navarro',
    //     'Vanessa Pessoa'
    //   ],
    // },
    // {
    //   categoria: 'Agentes de Fomento do Ano',
    //   indicados: [
    //     'BNDS',
    //     'Cietec',
    //     'Embrapii',
    //     'Endeavor',
    //     'FAPES',
    //     'FAPESP',
    //     'Finep',
    //     'Fundação Araucária',
    //     'Inovativa',
    //     'Linklab',
    //     'Sebrae',
    //     'Seed MG'
    //   ],
    // },
    // {
    //   categoria: 'Instituição de Crescimento do Ano',
    //   indicados: [
    //     'ACATE',
    //     'ACE Aceleradora',
    //     'B2 Mammy',
    //     'Celta Incubadora',
    //     'Darwin Startups',
    //     'FCJ',
    //     'InovAtiva',
    //     'InoveNow',
    //     'MIDITEC',
    //     'Novo Agro Ventures',
    //     'Sebrae',
    //     'Softville',
    //     'Troposlab',
    //     'Vale do Dendê',
    //     'Ventiur',
    //     'Wow Aceleradora'
    //   ],
    // },
    // {
    //   categoria: 'Investidor ou Investidora Anjo do Ano',
    //   indicados: [
    //     'Amure Pinho',
    //     'Anderson Diehl',
    //     'Angelica Nkyn',
    //     'Camila Farani',
    //     'Claudia Rosa',
    //     'Erica Fridman Stul',
    //     'Jaana Goeggel',
    //     'Jéssica Silva Rios',
    //     'Luana Ozemela',
    //     'Marco Poli',
    //     'Orlando Cintra',
    //     'Paulo Veras',
    //     'Rafael Silva',
    //     'Wlado Teixeira'
    //   ],
    // },{
    //   categoria: 'Iniciativa de Educação do Ano',
    //   indicados: [
    //     '49 Educação',
    //     'Alura',
    //     'B2 Mammy',
    //     'Escola Conquer',
    //     'FIAP',
    //     'InoveNow',
    //     'Investidores.vc',
    //     'Sapienza Brasil',
    //     'Startup SC',
    //     'UberHub Code Club',
    //     'Unicamp'
    //   ],
    // },
    // {
    //   categoria: 'Hub de Tecnologia do Ano',
    //   indicados: [
    //     'Acate',
    //     'Base 27',
    //     'CUBO',
    //     'Hub Salvador',
    //     'InovaBra Habitat',
    //     'Instituto Caldeira',
    //     'Learning Village',
    //     'Manaus Tech Hub',
    //     'Nexus',
    //     'NINNA Hub',
    //     'Orbi Conecta',
    //     'Porto Digital'
    //   ],
    // },
    // {
    //   categoria: 'Profissional de Imprensa do Ano',
    //   indicados: [
    //     'Adriano Nogueira',
    //     'Aline Costa',
    //     'AMANDA GRACIANO',
    //     'Ana Paula Dahlke',
    //     'Angelica Mari',
    //     'BÁRBARA ALMEIDA',
    //     'Beatriz Bevilacqua',
    //     'Bruno Pinheiro',
    //     'Carolina Monteiro',
    //     'Cecilia Bere',
    //     'Cintia Beck',
    //     'Danylo Martins',
    //     'Fabiana Ramos',
    //     'Fabricio Rodrigues',
    //     'Fabrício Umpierres',
    //     'Fernanda Santos',
    //     'Flávio Neves Carneiro',
    //     'GABRIELA DEL CARMEN',
    //     'Geraldo Samor',
    //     'Gustavo Brigatto',
    //     'Helga Rackel',
    //     'Isabella Kubera',
    //     'Janaina Leme',
    //     'Jaqueline Silva',
    //     'Jennifer Queen',
    //     'Leandro Miguel de Souza',
    //     'Leonardo Missio',
    //     'Lily Marchisio',
    //     'Lu Amâncio',
    //     'Lucas Agrela',
    //     'Luiz Gomes',
    //     'Maia Veloso',
    //     'Marco Tulio',
    //     'Marcone Buarque',
    //     'Marcos Bonfim',
    //     'Mariana Alves de Assis',
    //     'Marinna Guglielmoni Cortez',
    //     'Marystela Barbosa',
    //     'Michele Barcena',
    //     'Monique Femme',
    //     'Nicole Barros',
    //     'Patricia Knebel',
    //     'PAULO OZAKI',
    //     'Raquel Barreto',
    //     'Renata Jordao',
    //     'Rodrigo Lossio',
    //     'Rodrigo Terra',
    //     'Thomaz Gomes',
    //     'Tiago Schmidt',
    //     'Vinícius Cordoni',
    //     'Vinicius Santos',
    //     'Wenya Alecrim'
    //   ],
    // },
    // {
    //   categoria: 'Startup Revelação do Ano',
    //   indicados: [
    //     'BlinDog',
    //     'Faceponto',
    //     'Faço a Conta',
    //     'Guia da Alma',
    //     'IRANCHO',
    //     'Machion',
    //     'Ocalev',
    //     'Planne',
    //     'SEU BONÉ',
    //     'Twiggy',
    //     'Widde'
    //   ],
    // },
    // {
    //   categoria: 'Startup do Ano',
    //   indicados: [
    //     'Alana AI',
    //     'C6 Bank',
    //     'CondoConta',
    //     'Daki',
    //     'Exact sales',
    //     'InoveNow',
    //     'Maneje Bem',
    //     'Mottu',
    //     'Nagro Crédito Agro',
    //     'PISMO',
    //     'Plure',
    //     'Trashin'
    //   ],
    // },
    // {
    //   categoria: 'Comunidade Revelação do Ano',
    //   indicados: [
    //     'Açai Valley',
    //     'All Saints Bay',
    //     'Aquiri Valley',
    //     'Caju Valley',
    //     'Costa Valley',
    //     'Jerimum Valley',
    //     'Noroeste Valley',
    //     'Potiguaras Valley',
    //     'Ser Valley',
    //     'Startup MT',
    //     'Sururu Valley',
    //     'Tambaqui Valley'
    //   ],
    // },
    // {
    //   categoria: 'Comunidade do Ano',
    //   indicados: [
    //     'Erre Jota',
    //     'Jaraqui Valley',
    //     'Manguezal',
    //     'Rapadura Valley',
    //     'San Pedro Valley',
    //     'Soluíses',
    //     'Startup SC',
    //     'Tucuju Valley',
    //     'Vale do Pinhão',
    //     'Zero Onze'
    //   ],
    // },

  ]

  return (
    <>
      <Container>
        <Row>
          <Col sm={12}>
            <S.LimitedDiv first>
              <S.TxtHeading>
                Confira aqui os mais indicados de cada categoria!
              </S.TxtHeading>
              <S.TxtSub>
                A Academia Awards indicou e aqui estão eles. Os mais indicados de cada categoria do Startup Awards 2k24. É hora de vocês entrarem em ação para analisar, votar e determinar os 3 finalistas de cada categoria desse ano.
              </S.TxtSub>
            </S.LimitedDiv>
          </Col>
          <Col sm={12}>
            <S.LimitedDiv>
              <Row>
                {indicados10.map((cat, i) => (
                  <Col key={`category-${cat.categoria}-${i}`} sm={12}>
                    <S.TxtH2>{cat.categoria}</S.TxtH2>
                    {cat.indicados.sort().map((ind) => (
                      <S.TxtInd key={`indic-${cat.categoria}-${ind}`}>
                        {ind}
                      </S.TxtInd>
                    ))}
                  </Col>
                ))}
              </Row>

              <OutlinedButton onClick={() => history.push('/')}>
                VOLTAR AO INÍCIO
              </OutlinedButton>
            </S.LimitedDiv>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Indicados
