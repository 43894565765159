import Login from '../pages/Login'
import Home from '../pages/Home'
import Indicacao from '../pages/Indicacao'
import Register from '../pages/Register'
import OptIn from '../pages/OptIn'
import Votacao from '../pages/Votacao'
import Agradecimento from '../pages/Agradecimento'
import Regulamento from '../pages/Regulamento'
import Privacidade from '../pages/Privacidade'
import Profile from '../pages/Perfil'
import ForgotPassword from '../pages/ForgotPassword'
import RecoveryPassword from '../pages/RecoveryPassword'
import Indicados from '../pages/Indicados'
import Finalistas from '../pages/Finalistas'
import Historico from '../pages/Historico'
import Vencedores from '../pages/Vencedores'

export default [
  {route: '/', name: 'index', Component: Home},
  {route: '/login', name: 'login', Component: Login},
  {
    route: '/cadastrar',
    name: 'cadastrar',
    Component: Register,
  },
  {
    route: '/optin',
    name: 'optin',
    Component: OptIn,
    private: true,
  },
  {
    route: '/agradecimento',
    name: 'agradecimento',
    Component: Agradecimento,
    private: true,
    showMenu: true,
  },
  {
    route: '/perfil',
    name: 'perfil',
    Component: Profile,
    private: true,
    showMenu: true,
  },
  {
    route: '/regulamento',
    name: 'regulamento',
    Component: Regulamento,
  },
  {
    route: '/seguranca-e-privacidade',
    name: 'seguranca-e-privacidade',
    Component: Privacidade,
  },
  {
    route: '/indicacao',
    name: 'indicacao',
    Component: Indicacao,
    private: true,
    showMenu: true,
  },
  {
    route: '/votacao',
    name: 'votacao',
    Component: Votacao,
    private: true,
    showMenu: true,
  },
  {
    route: '/esqueci-a-senha',
    name: 'esqueci-a-senha',
    Component: ForgotPassword,
  },
  {
    route: '/changePassword',
    name: 'changePassword',
    Component: RecoveryPassword,
  },
  {
    route: '/mudar-a-senha',
    name: 'mudar-a-senha',
    Component: RecoveryPassword,
  },
  {
    route: '/indicados',
    name: 'indicados',
    Component: Indicados,
  },
  {
    route: '/finalistas',
    name: 'finalistas',
    Component: Finalistas,
  },
  {
    route: '/historico-do-evento',
    name: 'historico',
    Component: Historico,
  },
  {
    route: '/vencedores',
    name: 'vencedores',
    Component: Vencedores,
  },
]
