import styled from 'styled-components'
import colors from '../../styles/colors'
import CloseIcon from '../../assets/images/icon/close'

interface MenuWrapper {
  active?: boolean
}

interface Bullet {
  active?: boolean
}

export const MenuWrapper = styled('div')<MenuWrapper>`
  min-width: 70vw;
  width: 1200px;
  max-width: 74vw;
  position: fixed;
  top: 0;
  z-index: 100;
  right: -110%;
  height: 100vh;
  padding-top: 50px;
  background: ${colors.gradient.purple};
  transition: right 0.8s ease;
  ${(props) => props.active && 'right: 0;'}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 50px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.black.primary};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${colors.copper.primary};
  }

  @media (max-width: 1650px) {
    padding-top: 50px;
  }

  @media (max-width: 992px) {
    max-width: 90vw;
    padding-top: 30px;
  }

  @media (max-width: 600px) {
    max-width: 95vw;
  }
`
export const MenuContainer = styled.div`
  margin: 0 auto;
  width: 80%;
  height: 100%;
  position: relative;

  @media (max-width: 1650px) {
    width: 88%;
  }
  @media (max-width: 992px) {
    width: 96%;
  }
  @media (max-width: 600px) {
    width: 90%;
    height: auto;
  }
`
export const MenuHead = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  padding-bottom: 30px;
`

export const MenuHeadLogin = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.12;
  color: ${colors.white.primary};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.32s ease;
  position: relative;

  @media (max-width: 992px) {
    font-size: 14px;
  }

  &:hover {
    opacity: 0.8;
  }

  & .icon {
    position: relative;
    min-height: 2.5em;
    min-width: 2.5em;
    height: 2.5em;
    width: 2.5em;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before{
      content: "";
      position: absolute;
      display: block;
      top: 0;
      lef: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: ${colors.copper.primary};
      opacity: .2;
    }

    & svg{
      font-size: 26px;
      line-height: normal;
      color: ${colors.copper.primary};
    }
  }

  img.pencil {
    min-height: 1.25em;
    min-width: 1.25em;
    height: 1.25em;
    width: 1.25em;
    margin-right: 10px;
    position: absolute;
    left: 2em;
    top: 2em;
    transform: translate(-40%, -40%);
  }
`

export const MenuHeadRegister = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.12;
  color: ${colors.white.primary};
  cursor: pointer;
  transition: opacity 0.32s ease;

  @media (max-width: 992px) {
    font-size: 14px;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const MenuLogin = styled.div`
  border-top: 1px solid;
  border-color: ${colors.gray.hard};
  padding: 45px 0 25px;
`

export const MenuLoginContent = styled.div`
  max-width: 400px;
`

export const MenuLoginContentTitle = styled.p`
  text-align: left;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.15;
  letter-spacing: -0.03em;
  color: ${colors.white.primary};
  margin-bottom: 30px;

  @media (max-width: 600px) {
    font-size: 22px;
    text-align: left;
    line-height: 1.25;
  }

  @media (max-width: 325px) {
    font-size: 20px;
  }
`

export const MenuLoginRegister = styled.div`
  text-align: center;
  p {
    color: ${colors.gray.light};
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 17px;
    margin-top: -15px;
  }
  button {
    min-width: 70%;
  }
`

export const MenuItensWrapper = styled.nav`
  padding: 30px 0 15px;
  width: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${colors.gray.hard};
`
export const MenuItens = styled.ul`
  list-style: none;

  li {
    color: ${colors.white.primary};
    font-size: 22px;
    font-weight: 600;
    line-height: 120%;
    font-weight: medium;
    letter-spacing: -0.03em;
    margin-bottom: 15px;
    font-family: 'Montserrat';
    cursor: pointer;
    transition: opacity 0.32s ease;

    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 1100px) {
      font-size: 20px;
      margin-bottom: 20px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }
`

export const XIcon = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${colors.copper.primary};
  cursor: pointer;
`
export const MenuVotesWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
`

export const MenuVotesContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: flex-start;
  align-items: stretch;
  grid-gap: 20px;
  padding-bottom: 30px;

  @media (max-width: 1450px) and (min-width: 1101px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1100px) and (min-width: 601px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 600px) {
    grid-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }
`
export const MenuVotesTitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.07em;
  color: ${colors.white.primary};
  text-transform: uppercase;
`

export const MenuVotesCard = styled.div`
  width: 100%;
  background: ${colors.gray.third};
  padding: 1em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  min-height: 4.22em;
  height: auto;
  font-size: 11px;
  color: ${colors.white.primary};
  text-transform: uppercase;
  letter-spacing: 0.4px;
  line-height: 130%;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 1px 1px ${colors.gray.light};
  }

  p {
    max-width: 82%;
    overflow: hidden;
  }

  @media (max-width: 600px) {
    padding: 0.5em 1em;
    font-size: 10px;
  }

  @media (max-width: 325px) {
    font-size: 8px;
  }
`

export const MenuVotesCardBullet = styled('div')<Bullet>`
  width: 1.5em;
  height: 1.5em;
  min-width: 1.5em;
  min-height: 1.5em;
  max-width: 1.5em;
  max-height: 1.5em;
  border: 1px solid ${colors.copper.primary};
  border-radius: 50%;
  ${(props) => (props.active ? `background: ${colors.copper.primary}` : '')};

  @media (max-width: 600px) {
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    max-width: 1em;
    max-height: 1em;
  }
`
