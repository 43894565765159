import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-grid-system'
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {toast} from 'react-toastify'
import {useHistory} from 'react-router-dom'
import {Container} from '../../styles/globals'
import * as S from './styles'
import TextField from '../../components/Inputs/TextField'
import SelectField from '../../components/Inputs/SelectField'
import PasswordField from '../../components/Inputs/PasswordField'
import NewButton from '../../components/Buttons/NewButton'
import Realization from '../../components/Realization'

import OutlinedButton from '../../components/Buttons/OutlinedButton'

import ufList from '../../utils/ufList'

import AppLoader from '../../components/AppLoader'

import SwitchField from '../../components/Inputs/SwitchField'

import {useAuth} from '../../context/AuthContext/Context'

import userService from '../../service/users'


interface IFormInputs {
  name: string
  email: string
  office: string
  state: string
  city: string
  password: string | undefined
}

interface UserTerms {
  status: boolean,
  term: {
    id: string,
    type: string
  }
}

const schema = yup.object({
  name: yup
    .string()
    .min(3, 'Pelo menos 3 caracteres')
    .required('Este campo é obrigatório'),
  email: yup
    .string()
    .min(5, 'Pelo menos 5 caracteres')
    .email('Digite um e-mail válido')
    .required('Este campo é obrigatório'),
  office: yup
    .string()
    .min(2, 'Pelo menos 2 caracteres')
    .required('Este campo é obrigatório'),
  state: yup.string().ensure().required('Este campo é obrigatório'),
  city: yup
    .string()
    .ensure()
    .min(3, 'Pelo menos 3 caracteres')
    .required('Este campo é obrigatório'),
  password: yup.string().when({
    is: (value: string) => value && value.length > 0,
    then: yup
      .string()
      .min(8, 'Digite pelo menos 8 caracteres')
      .max(20, 'Digite pelo no máximo 20 caracteres')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).{8,256}$/,
        'Deve conter pelo menos 8 caracteres, um caractere especial, uma letra maiúscula e uma minúscula.',
      ),
    otherwise: yup.string().nullable(),
  }),
})

const Profile: React.FC = () => {
  const {user, logout} = useAuth()

  const [loading, setLoading] = useState(true)

  const [sending, setSending] = useState(false)

  const [modal, setModal] = useState(false)

  const [agreeContact, setAgreeContact] = useState(true)

  const history = useHistory()

  let defaultValues = {}

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues,
  })

  useEffect(() => {
    if (user) {
      const loadUserData = async () => {
        await userService
          .getById({
            id: user.id,
            token: user.token,
          })
          .then(({userTerms, data}) => {
            defaultValues = {
              name: data.name,
              email: data.email,
              office: data.office,
              state: data.state,
              city: data.city,
              password: '',
            }
            // Status Contato (LGPD)
            const lgpd =
              userTerms &&
              userTerms.length &&
              userTerms.filter((e: UserTerms) => e.term.type === 'LGPD')[0].status

            setAgreeContact(lgpd)
            reset(defaultValues)
            setLoading(false)
          })
      }
      loadUserData()
    }
  }, [])

  const handleDelete = async () => {
    if (user) {
      await userService
        .deleteUser({
          id: user.id,
          token: user.token,
        })
        .then(() => {
          toast.success('Conta excluída com sucesso!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          logout()
        })
        .catch((error) => {
          toast.error(
            Array.isArray(error.response.data.modal)
              ? error.response.data.modal[0]
              : error.response.data.modal,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          )
        })
    }
    setModal(false)
  }

  const checkErrors = () => {
    if (
      errors &&
      Object.keys(errors).length &&
      Object.keys(errors).length !== 0
    ) {
      toast.error('Verifique os campos do formulário', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const onSubmit = async (data: IFormInputs) => {
    setSending(true)

    if (user) {
      // LGPD, segundo botão, contato
      await userService.putTerms({
        userId: user.id,
        term: 'LGPD',
        status: agreeContact,
        token: user.token,
      })
    }

    let formData: IFormInputs = {
      name: data.name,
      email: data.email,
      office: data.office,
      state: data.state,
      city: data.city,
      password: undefined,
    }

    if (data.password) {
      formData = {
        ...formData,
        password: data.password,
      }
    }

    await userService
      .putUser({
        ...formData,
        id: user?.id,
        token: user?.token,
      })
      .then(() => {
        setSending(false)
        toast.success('Dados atualizados!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        history.push('/')
      })
      .catch((error) => {
        setSending(false)
        toast.error(
          Array.isArray(error.response.data.modal)
            ? error.response.data.modal[0]
            : error.response.data.modal,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        )
      })
  }

  return (
    <>
      {loading ? (
        <AppLoader />
      ) : (
        <>
          <Container>
            <S.ProfileWrapper>
              <S.ProfileForm onSubmit={handleSubmit(onSubmit)}>
                <S.TxtInfo>Edite seus dados</S.TxtInfo>
                <Row>
                  <Col sm={12}>
                    <Controller
                      control={control}
                      name="name"
                      defaultValue=""
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <S.WrapperFields first>
                          <TextField
                            label="Nome"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            inputRef={ref}
                            error={!!errors && !!errors.name}
                            errorMessage={
                              !!errors && !!errors.name
                                ? errors.name.message
                                : ''
                            }
                          />
                        </S.WrapperFields>
                      )}
                    />
                    <Controller
                      control={control}
                      name="email"
                      defaultValue=""
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <S.WrapperFields>
                          <TextField
                            disabled={true}
                            label="E-mail"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            inputRef={ref}
                            error={!!errors && !!errors.email}
                            errorMessage={
                              !!errors && !!errors.email
                                ? errors.email.message
                                : ''
                            }
                          />
                        </S.WrapperFields>
                      )}
                    />
                    <Controller
                      control={control}
                      name="office"
                      defaultValue=""
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <S.WrapperFields>
                          <TextField
                            label="Empresa"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            inputRef={ref}
                            error={!!errors && !!errors.office}
                            errorMessage={
                              !!errors && !!errors.office
                                ? errors.office.message
                                : ''
                            }
                          />
                        </S.WrapperFields>
                      )}
                    />
                    <S.LimitedDiv>
                      <S.WrapperFields>
                        <Row>
                          <Col sm={3}>
                            <Controller
                              control={control}
                              name="state"
                              defaultValue=""
                              render={({
                                field: {onChange, onBlur, value, ref},
                              }) => (
                                <SelectField
                                  options={['', ...ufList]}
                                  label="Estado"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  inputRef={ref}
                                  fullWidth
                                  error={!!errors && !!errors.state}
                                  errorMessage={
                                    !!errors && !!errors.state
                                      ? errors.state.message
                                      : ''
                                  }
                                />
                              )}
                            />
                          </Col>
                          <Col sm={9}>
                            <Controller
                              control={control}
                              name="city"
                              defaultValue=""
                              render={({
                                field: {onChange, onBlur, value, ref},
                              }) => (
                                <TextField
                                  autoComplete="new-password"
                                  label="Cidade"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                  inputRef={ref}
                                  error={!!errors && !!errors.city}
                                  errorMessage={
                                    !!errors && !!errors.city
                                      ? errors.city.message
                                      : ''
                                  }
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </S.WrapperFields>
                    </S.LimitedDiv>
                    <Controller
                      control={control}
                      name="password"
                      defaultValue=""
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <S.WrapperFields>
                          <PasswordField
                            label="Senha"
                            autoComplete="new-password"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            inputRef={ref}
                            error={!!errors && !!errors.password}
                            errorMessage={
                              !!errors && !!errors.password
                                ? errors.password.message
                                : ''
                            }
                          />
                        </S.WrapperFields>
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <S.LastDiv>
                      <SwitchField
                        label="Autorizo o uso dos meus dados para envio de informações relevantes pela ABStartups."
                        checked={agreeContact}
                        onChange={() => setAgreeContact(!agreeContact)}
                      />
                    </S.LastDiv>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <S.WrapperActionButton>
                      <NewButton
                        disabled={sending}
                        isLoading={sending}
                        type="submit"
                        onClick={checkErrors}>
                        Salvar Edição
                      </NewButton>
                      <S.TxtDelete onClick={() => setModal(true)}>
                        Deletar Conta
                      </S.TxtDelete>
                    </S.WrapperActionButton>
                  </Col>
                </Row>
              </S.ProfileForm>
              <Realization />
            </S.ProfileWrapper>
          </Container>
          <S.ModalDeleteWrapper active={modal}>
            <S.ModalDelete>
              <S.ModalDeleteTitle>
                Deseja realmente deletar sua conta?
              </S.ModalDeleteTitle>
              <S.ModalDeleteText>
                Ao deletar sua conta todos os seus dados serão excluídos
                permanentemente e não podem ser recuperados.
                <S.ModalDeleteBtnWrapper>
                  <OutlinedButton onClick={() => handleDelete()}>
                    Deletar
                  </OutlinedButton>
                  <NewButton onClick={() => setModal(false)}>
                    Cancelar
                  </NewButton>
                </S.ModalDeleteBtnWrapper>
              </S.ModalDeleteText>
            </S.ModalDelete>
          </S.ModalDeleteWrapper>
        </>
      )}
    </>
  )
}

export default Profile
