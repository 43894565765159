import React from 'react'

interface Svg {
  onClick(): void
}

const PlayIcon: React.FC<Svg> = (props) => {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20.5125 12.15L2.525 1.15C2.37358 1.05659 2.19998 1.00528 2.02211 1.00134C1.84424 0.997409 1.66854 1.041 1.51314 1.12762C1.35774 1.21424 1.22826 1.34075 1.13805 1.4941C1.04785 1.64745 1.0002 1.82208 1 2V24C1.0002 24.1779 1.04785 24.3525 1.13805 24.5059C1.22826 24.6592 1.35774 24.7858 1.51314 24.8724C1.66854 24.959 1.84424 25.0026 2.02211 24.9986C2.19998 24.9947 2.37358 24.9434 2.525 24.85L20.5125 13.85C20.6602 13.7627 20.7826 13.6383 20.8676 13.4893C20.9526 13.3402 20.9974 13.1716 20.9974 13C20.9974 12.8284 20.9526 12.6598 20.8676 12.5107C20.7826 12.3617 20.6602 12.2373 20.5125 12.15V12.15Z"
        stroke="#FFD222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlayIcon
