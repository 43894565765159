import React, {useState, useEffect} from 'react'
import NewButton from '../Buttons/NewButton'
import CustomModal from '../CustomModal'
import * as S from './styles'

const ApresentacaoIndicacoes: React.FC = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const isAware = localStorage.getItem('indicationsModal')
    if (!isAware) {
      setOpen(true)
    }
    document.addEventListener(
      'keydown',
      (e) => {
        if (e.keyCode === 27) {
          setOpen(false)
        }
      },
      false,
    )
  }, [])

  const handleClick = () => {
    setOpen(false)
    const date = new Date()
    localStorage.setItem('indicationsModal', date.toString())
  }

  return open ? (
    <CustomModal
      title={"Chegou a sua hora"}
      btnText={"Vamos Indicar ;)"}
      center={true}
      btnAction={()=>{handleClick()}}
      onClose={
        ()=>{setOpen(false)}
      }
    >
      <p>
        Você tem a oportunidade de indicar grandes talentos para cada categoria do prêmio mais importante do ecossistema de inovação do país. Faça agora suas indicações.
      </p>
    </CustomModal>
  ) : (
    <></>
  )
}

export default ApresentacaoIndicacoes
