import React, {useEffect, useState} from 'react'

import {Col, Container, Row} from 'react-grid-system'

import {useHistory} from 'react-router'

import {toast} from 'react-toastify'

import {getTermByType} from '../../service/terms'

import AppLoader from '../../components/AppLoader'
import Realization from '../../components/Realization'

import * as S from './styles'

const Regulamento: React.FC = () => {
  const history = useHistory()

  const [term, setTerm] = useState('')

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadTerm = async () => {
      await getTermByType({type: 'Termos e condições'})
        .then(({data}) => {
          setTerm(data.description)
          setLoading(false)
        })
        .catch((error) => {
          toast.error(
            Array.isArray(error.response.data.modal)
              ? error.response.data.modal[0]
              : error.response.data.modal,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          )
          setLoading(false)
        })
      return
    }
    loadTerm()
  }, [])

  return loading ? (
    <AppLoader />
  ) : (
    <>
      <Container>
        <Row>
          <Col sm={12}>
            <S.LimitedDiv first>
              <S.TxtHeading>Regulamento</S.TxtHeading>
              <S.XIcon onClick={() => history.goBack()} />
            </S.LimitedDiv>
          </Col>
          <Col sm={12}>
            <S.LimitedDiv>
              <S.Wrapper>
                <S.Content>{term}</S.Content>
              </S.Wrapper>
            </S.LimitedDiv>
          </Col>
          <Col sm={12}>
            <Realization />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Regulamento
