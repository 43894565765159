import styled from 'styled-components'
import colors from '../../styles/colors'

const warningIcon = (side:string) => `
  content: "!";
  position absolute;
  top: 50%;
  ${side}: 100px;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 5px;
  background-color: ${colors.black.primary};
  color: ${colors.copper.primary};
  font-size: 1.6rem;
  line-height: 4px;
  font-weight: 700;

  @media (max-width: 600px) {
    display none;
  }
`

export const TopWarning = styled.div`
  width: 100%;
  background: ${colors.copper.primary};
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding: 8px 150px;

  & p{
    display: block;
    text-align: center;
    color: ${colors.black.primary};
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 125%;
    max-width: 70%;

    & b{
      font-weight: 700;
    }
  }

  &:before{
    ${warningIcon("left")}
  }
  &:after{
    ${warningIcon("right")}
  }

  @media (max-width: 600px) {
    padding: 8px 40px;

    & p{
      max-width: 100%;
      font-size: 0.8rem;
      line-height: 115%;
    }
  }

`
