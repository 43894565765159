import React, {useState} from 'react'

import {Col, Container, Row} from 'react-grid-system'

import {toast} from 'react-toastify'

import {useHistory} from 'react-router-dom'

import * as S from './styles'

import OutlinedButton from '../../components/Buttons/OutlinedButton'

import {useAuth} from '../../context/AuthContext/Context'

import {useApp} from '../../context/AppContext/Context'

import userService from '../../service/users'

import stepsService from '../../service/steps'

import SwitchField from '../../components/Inputs/SwitchField'

import Realization from '../../components/Realization'

import {Step} from '../../models/Step'

import {steps} from '../../context/VotationContext/Provider'
import NewButton from '../../components/Buttons/NewButton'

const OptIn: React.FC = () => {
  const history = useHistory()

  const {user, logout} = useAuth()

  const {isLoading, setIsLoading} = useApp()

  const [agreeTerms, setAgreeTerms] = useState(true)

  const [agreeContact, setAgreeContact] = useState(true)

  const handleOptIn = async () => {
    setIsLoading(true)

    if (!agreeTerms) {
      toast.error(
        'Você deve aceitar os regulamentos do prêmio e da academia, assim como suas políticas de privacidade para continuar',
        {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      )
      setIsLoading(false)
      return
    }

    if (user) {
      const {
        data: {stage},
      } = await stepsService.getCurrentStep({
        token: user.token,
      })

      // Checar se o usuário pode logar na fase atual
      userService
        .getById({
          id: user.id,
          token: user.token,
        })
        .then(({data}) => {
          if(data.role.role === 'Comum'){
            if (stage === 'Fase 1') {
              toast.error('Você não tem acesso ao estagio atual da votação', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
              logout()
              return
            }else if(stage === 'Fase 3'){
              toast('No estágio atual a academia está votando. Mas você pode visualizar os concorrentes.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          }
        })
        .catch((error) => {
          toast.error(
            Array.isArray(error.response.data.modal)
              ? error.response.data.modal[0]
              : error.response.data.modal,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          )
          return
        })

      // Privacidade, primeiro botão
      const privacyTerms = await userService.putTerms({
        userId: user.id,
        term: 'Política de privacidade',
        status: agreeTerms,
        token: user.token,
      })

      // Regulamento, também primeiro botão
      const awardTerms = await userService.putTerms({
        userId: user.id,
        term: 'Termos e condições',
        status: agreeTerms,
        token: user.token,
      })

      // LGPD, segundo botão, contato
      const lgpdTerms = await userService.putTerms({
        userId: user.id,
        term: 'LGPD',
        status: agreeContact,
        token: user.token,
      })

      Promise.all([privacyTerms, awardTerms, lgpdTerms])
        .then(() => {
          toast.success('Tudo certo!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setIsLoading(false)

          const step: Step = steps[
            stage as 'Fase 1' | 'Fase 2' | 'Fase 3'
          ] as Step
          return history.push(step.page)
        })
        .catch((error) => {
          toast.error(
            'Ocorreu um erro. Tente novamente, caso o erro persita entre em contato.',
            {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          )
          console.log(error)
          setIsLoading(false)
        })
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col sm={12}>
            <S.LimitedDiv first>
              <S.TxtWarning>Antes de iniciar</S.TxtWarning>
              <S.TxtInfo>
                Antes de registrar seus dados, você deve estar de concordo com o
                regulamento e política de privacidade do Startup Awards 2k24.
              </S.TxtInfo>
            </S.LimitedDiv>
          </Col>
          <Col sm={12}>
            <S.LimitedDiv>
              <OutlinedButton
                className="outBtn"
                isGray
                onClick={() => history.push('/seguranca-e-privacidade')}>
                SEGURANÇA E PRIVACIDADE
              </OutlinedButton>
            </S.LimitedDiv>
          </Col>

          <Col sm={12}>
            <S.LimitedDiv>
              <OutlinedButton
                className="outBtn"
                isGray
                onClick={() => history.push('/regulamento')}>
                REGULAMENTO
              </OutlinedButton>
            </S.LimitedDiv>
          </Col>

          {/* <Col sm={12}>
            <S.LimitedDiv>
              <OutlinedButton
                className="outBtn"
                isGray
                onClick={() => window.open("https://docs.google.com/presentation/d/e/2PACX-1vTbKFWoeeszO13LleNRudq3Bla7i0pf8OqN-PgJjOxOSmKvTUF_XG9aI0oOpPY631iXV3FdzTeGY2NF/pub?start=false&loop=false&delayms=3000", "_blank")}>
                REGULAMENTO DA ACADEMIA
              </OutlinedButton>
            </S.LimitedDiv>
          </Col> */}

          <Col sm={12}>
            <S.LimitedDiv>
              <S.SwitchWrapper>
                <SwitchField
                  label="Eu li e concordo com o regulamento do prêmio e o regulamento da academia, assim como suas políticas de privacidade."
                  checked={agreeTerms}
                  onChange={() => setAgreeTerms(!agreeTerms)}
                />
              </S.SwitchWrapper>
            </S.LimitedDiv>
          </Col>

          <Col sm={12}>
            <S.LimitedDiv>
              <S.SwitchWrapper>
                <SwitchField
                  label="Autorizo o uso dos meus dados para envio de informações relevantes pela ABStartups."
                  checked={agreeContact}
                  onChange={() => setAgreeContact(!agreeContact)}
                />
              </S.SwitchWrapper>
            </S.LimitedDiv>
          </Col>

          <Col sm={12}>
            <S.LimitedDiv>
              <NewButton
                className="newBtn"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={handleOptIn}>
                Estou de acordo
              </NewButton>
            </S.LimitedDiv>
          </Col>

          <Col sm={12}>
            <Realization />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default OptIn
