import React from 'react'
import * as S from './styles'
import btnIcon from '../../../assets/images/icon/next-2023.png'

interface Button {
  onClick(): void
  disable: boolean
}

interface PaginationButtonProps {
  prevButton: Button
  nextButton: Button;
}

const PaginationsButton: React.FC<PaginationButtonProps> = ({
  prevButton,
  nextButton,
}) => {
  return (
    <S.WrapperButton>
      <S.PaginationButton disabled={prevButton.disable} onClick={prevButton.onClick}>
        <S.PrevIcon src={btnIcon} />
      </S.PaginationButton>
      <S.PaginationButton disabled={nextButton.disable} onClick={nextButton.onClick}>
        <img src={btnIcon} />
      </S.PaginationButton>
    </S.WrapperButton>
  )
}

export default PaginationsButton
