import styled from 'styled-components'
import colors from '../../styles/colors'
import CloseIcon from '../../assets/images/icon/close'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.32s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background: ${colors.black.primary70};

  &[class~='active'] {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
`

export const Modal = styled.div`
  width: 60%;
  max-width: 900px;
  position: relative;
  background: ${colors.black.secondary};

  @media (max-width: 760px) and (orientation: portrait) {
    width: 90%;
  }

  @media (max-width: 760px) and (orientation: landscape) {
    width: 75%;
  }
`

export const IframeWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  content-visibility: auto;

  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export const Placeholder = styled.div`
  width: 100%;
  padding-top: 56.25%;
`

export const XIcon = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-120%);
  font-size: 30px;
  cursor: pointer;
  color: ${colors.white.primary};
`
