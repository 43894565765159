import styled from 'styled-components'
import colors from '../../../styles/colors'

interface IndicationField {
  isChecked?: boolean
}

export const Input = styled.input<IndicationField>`
  background: transparent;
  border: 1px solid ${colors.copper.primary};
  width: 100%;
  height: 42px;
  border-radius: 4px;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: ${colors.gray.light};
  padding: 8px 10px 2px;
  outline: 0;
  box-sizing: border-box;

  &::placeholder {
    font-size: 12px;
    color: ${colors.gray.fourth};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #141414 inset;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #ffffff !important;
  }

  &:focus {
    outline: none !important;
  }

  &:disabled{
    border-color: ${(props) => props.isChecked ? `${colors.green.primary}` : `${colors.gray.fourth}`};
    color: ${colors.green.primary};
  }
`

export const Wrapper = styled.div`
  display: block;
  position: relative;
  margin: 10px auto 20px;

  & > label {
    display: block;
    font-family: 'Montserrat';
    color: ${colors.gray.fourth};
    display: block;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 12px;
    transform: translateY(-50%);
    background: ${colors.black.primary};
    padding: 0 2px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
`
