import styled from 'styled-components'
import colors from '../../../styles/colors'

interface NewButton {
  isChecked?: boolean
}

export const Button = styled.button<NewButton>`
  padding: 9px 24px;
  color: ${colors.black.primary};
  background-color: ${(props) =>
    props.isChecked ? `${colors.green.primary}` : `${colors.copper.primary}`};
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 14px;
  border: none;
  cursor: ${(props) =>
    props.isChecked ? `default` : `pointer`};
  text-transform: uppercase;
  border-radius: 4px;
  transition: ease .3s;

  &:hover{
    background-color: ${colors.black.primary};
    color: ${colors.copper.primary};
    box-shadow: ${colors.copper.primary} inset 0px 0px 0px 2px;
  }

  &.outline{
    color: ${(props) =>
      props.isChecked ? `${colors.green.primary}` : `${colors.copper.primary}`};
    background-color: ${colors.black.secondary};
    box-shadow: ${(props) =>
      props.isChecked ? `${colors.green.primary}` : `${colors.copper.primary}`} inset 0px 0px 0px 2px;

    &:hover{
      background-color: ${(props) =>
        props.isChecked ? `${colors.green.primary}` : `${colors.copper.primary}`};
      color: ${colors.black.primary};
    }

    &:disabled {
      border-color: ${colors.gray.fourth};
      color: ${colors.black.primary};
    }
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    background-color: ${colors.gray.fourth}
  }

  & img {
    width: 13px;
  }
`
