import React from 'react'

import OutlinedButton from '../../components/Buttons/OutlinedButton'

import {Col, Container, Row} from 'react-grid-system'

import {useHistory} from 'react-router'

import * as S from './styles'
import Footer from '../../components/Footer'

const Finalistas: React.FC = () => {
  const history = useHistory()

  const finalistas = [
    {
      categoria: 'Em breve',
      indicados: []
    },
    // {
    //   categoria: 'Mentor ou Mentora do Ano',
    //   indicados: [
    //     'Ale Uehara',
    //     'Leandro Piazza',
    //     'Renata Luz',
    //   ],
    // },
    // {
    //   categoria: 'Venture Capital do Ano',
    //   indicados: [
    //     'ACE Ventures',
    //     'Anjos do Brasil',
    //     'Bossa Invest',
    //   ],
    // },
    // {
    //   categoria: 'Corporate do Ano',
    //   indicados: [
    //     'Algar',
    //     'Google',
    //     'NATURA',
    //   ],
    // },
    // {
    //   categoria: 'Herói ou Heroína do Ano',
    //   indicados: [
    //     'Alexandre Souza',
    //     'Ferdinando Kun',
    //     'Vanessa Pessoa',
    //   ],
    // },
    // {
    //   categoria: 'Agentes de Fomento do Ano',
    //   indicados: [
    //     'InovAtiva',
    //     'Sebrae',
    //     'Seed MG',
    //   ],
    // },
    // {
    //   categoria: 'Instituição de Crescimento do Ano',
    //   indicados: [
    //     'ACATE',
    //     'FCJ',
    //     'SEBRAE',
    //   ],
    // },
    // {
    //   categoria: 'Investidor ou Investidora Anjo do Ano',
    //   indicados: [
    //     'Amure Pinho',
    //     'Camila Farani',
    //     'Paulo Veras',
    //   ],
    // },
    // {
    //   categoria: 'Iniciativa de Educação do Ano',
    //   indicados: [
    //     '49 Educação',
    //     'Alura',
    //     'Startup SC',
    //   ],
    // },
    // {
    //   categoria: 'Hub de Tecnologia do Ano',
    //   indicados: [
    //     'ACATE',
    //     'Cubo',
    //     'Instituto Caldeira',
    //   ],
    // },
    // {
    //   categoria: 'Profissional de Imprensa do Ano',
    //   indicados: [
    //     'Beatriz Bevilacqua',
    //     'Bárbara Almeida',
    //     'Rodrigo Terra',
    //   ],
    // },
    // {
    //   categoria: 'Startup Revelação do Ano',
    //   indicados: [
    //     'BlinDog',
    //     'Guia da Alma',
    //     'Ocalev',
    //   ],
    // },
    // {
    //   categoria: 'Startup do Ano',
    //   indicados: [
    //     'C6 Bank',
    //     'Exact sales',
    //     'Nagro Crédito Agro',
    //   ],
    // },
    // {
    //   categoria: 'Comunidade Revelação do Ano',
    //   indicados: [
    //     'Açaí Valley',
    //     'Ser Valley',
    //     'Tambaqui Valley',
    //   ],
    // },
    // {
    //   categoria: 'Comunidade do Ano',
    //   indicados: [
    //     'Rapadura Valley',
    //     'Startup SC',
    //     'Zero Onze',
    //   ],
    // },

  ]

  return (
    <>
      <Container>
        <Row>
          <Col sm={12}>
            <S.LimitedDiv first>
              <S.TxtHeading>
                Confira aqui os 3 finalistas de cada categoria!
              </S.TxtHeading>
              <S.TxtSub>
                Vocês entraram em ação e escolheram os 3 finalistas de
                cada categoria do Startup Awards 2k24. Confira os escolhidos e
                se prepare, pois, falta pouco para conhecermos os vencedores da
                nossa premiação.
              </S.TxtSub>
            </S.LimitedDiv>
          </Col>
          <Col sm={12}>
            <S.LimitedDiv>
              <Row>
                {finalistas.map((cat, i) => (
                  <Col key={`category-${cat.categoria}-${i}`} sm={12}>
                    <S.TxtH2>{cat.categoria}</S.TxtH2>
                    {cat.indicados.map((ind) => (
                      <S.TxtInd key={`indic-${cat.categoria}-${ind}`}>
                        {ind}
                      </S.TxtInd>
                    ))}
                  </Col>
                ))}
              </Row>

              <OutlinedButton onClick={() => history.push('/')}>
                VOLTAR AO INÍCIO
              </OutlinedButton>
            </S.LimitedDiv>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Finalistas
