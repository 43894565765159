import React, {useEffect} from 'react'

import * as S from './styles'

export interface VideoModalProps {
  videoId: string
  open: boolean
  close(): void
}

const VideoModal: React.FC<VideoModalProps> = ({videoId, open, close}) => {
  useEffect(() => {
    document.addEventListener(
      'keydown',
      (e) => {
        if (e.keyCode === 27) {
          close && close()
        }
      },
      false,
    )
  }, [])

  return (
    <S.Wrapper className={`${open ? 'active' : ''}`}>
      <S.Modal>
        <S.XIcon onClick={() => close && close()} />
        {open ? (
          <S.IframeWrapper>
            <iframe
              id="ytplayer"
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&showinfo=0&modestbranding=1&iv_load_policy=3&rel=0`}
              frameBorder="0"
            />
          </S.IframeWrapper>
        ) : (
          <S.Placeholder />
        )}
      </S.Modal>
    </S.Wrapper>
  )
}

export default VideoModal
