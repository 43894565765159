export const BASE_URL_PROD = 'https://startupawards-api-prod.azurewebsites.net'
export const BASE_URL_HOMOLOG = 'https://startupawards-api-homolog.azurewebsites.net'
export const BASE_URL_DEV = 'http://localhost:8080'

const siteEnv = process.env.REACT_APP_SITE_ENV;

export const BASE_URL = siteEnv === "PROD" ? BASE_URL_PROD : siteEnv === "HOMOLOG" ? BASE_URL_HOMOLOG : BASE_URL_DEV

const USER_API = {
  GET_ID: (id) => {
    return `${BASE_URL}/users/getById?id=${id}`
  },
  LOGIN_APP: `${BASE_URL}/users/app/login`,
  POST: `${BASE_URL}/users/app`,
  POST_TERMS: `${BASE_URL}/users/createUserTerm`,
  PUT: `${BASE_URL}/users`,
  PUT_TERMS: `${BASE_URL}/users/setUserTermStatus`,
  PUT_ALL_TERMS: `${BASE_URL}/users/setAllTerms`,
  DELETE: `${BASE_URL}/users`,
  LOGIN: `${BASE_URL}/users/app/login`,
  FORGOT_PASSWORD: `${BASE_URL}/users/forgotPassword`,
  CHANGE_PASSWORD: `${BASE_URL}/users/updatePassword`,
}

const TERMS = {
  GET_TYPE: (type) => {
    return `${BASE_URL}/terms/getByType?type=${type}`
  },
}

const STEPS = {
  GET_CURRENT_STEP: `${BASE_URL}/stages/getActiveStage`,
}

const CATEGORIES = {
  GET_CATEGORIES_WITH_PARTNER_AND_VOTES: (userId, order, stageId) =>
    `${BASE_URL}/categories/list?page=1&limit=100&search&orderField=order&orderDirection=ASC&userId=${userId}&stage=${order}&stageId=${stageId}`,
}

const VOTE = {
  INDICATE: `${BASE_URL}/indications`,
  POLL: `${BASE_URL}/polls`,
}

const EMAIL = {
  SEND_EMAIL: `${BASE_URL}/emails/sendEmail`,
  SEND_EMAIL_MEDIA_KIT: `${BASE_URL}/emails/site/sendEmailMediaKit`,
}

const RECAPTCHA = {
  VALIDATE: (captcha) => {
    return `${BASE_URL}/recaptchaValidation/site/validate?captcha=${captcha}`
  },
}

const APPSTATUS = {
  GET: `${BASE_URL}/appstatus`,
}

const SITE = {
  GET_CATEGORIES: `${BASE_URL}/categories/site/list`,
}

export {
  USER_API,
  TERMS,
  STEPS,
  CATEGORIES,
  VOTE,
  EMAIL,
  APPSTATUS,
  SITE,
  RECAPTCHA,
}
