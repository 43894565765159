import styled from 'styled-components'
import colors from '../../../styles/colors'

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > :first-child {
    margin-right: 46px;
  }
`

export const PaginationButton = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: ${colors.black.primary};
  border: 1px solid ${colors.copper.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const PrevIcon = styled.img`
  transform: rotate(180deg);
`
