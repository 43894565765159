import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import * as S from './styles'
import IndicationField from '../../components/Inputs/IndicationField'
import {Container} from '../../styles/globals'
import {useVotation} from '../../context/VotationContext/Context'
import {getImgPath} from '../../utils'
import {useHistory} from 'react-router-dom'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import nextBtn from '../../assets/images/icon/next-2023.png'
import prevBtn from '../../assets/images/icon/prev-2023.png'
import NewButton from '../../components/Buttons/NewButton'
import Realization from '../../components/Realization'
import msLogo from '../../assets/images/logos/microsoft.svg'
import ApresentacaoIndicacoes from '../../components/ApresentacaoIndicacoes'
import { TbCheck, TbArrowBigRightLinesFilled } from 'react-icons/tb'


const Indicacao: React.FC = () => {
  const [criterionIsOpen, setCriterionIsOpen] = useState(false)
  const [label, setLabel] = useState('LINKEDIN CORRESPONDENTE')
  const history = useHistory()
  const {currentCategory, currentStep, prevButton, nextButton, makeIndication} =
    useVotation()

  type votesObject = {
    0: string | undefined;
    1: string | undefined;
    2: string | undefined;
    3: string | undefined;
    4: string | undefined;
  };

  const [indicationField, setIndicationField] = useState<votesObject>({
    0: currentCategory?.vote[0]?.voted ? currentCategory.vote[0].indicationName : '',
    1: currentCategory?.vote[1]?.voted ? currentCategory.vote[1].indicationName : '',
    2: currentCategory?.vote[2]?.voted ? currentCategory.vote[2].indicationName : '',
    3: currentCategory?.vote[3]?.voted ? currentCategory.vote[3].indicationName : '',
    4: currentCategory?.vote[4]?.voted ? currentCategory.vote[4].indicationName : ''
  })
  const [linkedinField, setLinkedinField] = useState<votesObject>({
    0: currentCategory?.vote[0]?.voted ? currentCategory.vote[0].linkedin : '',
    1: currentCategory?.vote[1]?.voted ? currentCategory.vote[1].linkedin : '',
    2: currentCategory?.vote[2]?.voted ? currentCategory.vote[2].linkedin : '',
    3: currentCategory?.vote[3]?.voted ? currentCategory.vote[3].linkedin : '',
    4: currentCategory?.vote[4]?.voted ? currentCategory.vote[4].linkedin : ''
  })

  const [checked, setChecked] = useState<Record<number, boolean | undefined>>({
    0: currentCategory?.vote[0]?.voted ? true : false,
    1: currentCategory?.vote[1]?.voted ? true : false,
    2: currentCategory?.vote[2]?.voted ? true : false,
    3: currentCategory?.vote[3]?.voted ? true : false,
    4: currentCategory?.vote[4]?.voted ? true : false
  })

  const maxIndications = 5;

  useEffect(() => {
    //Fill fields and mark voteds
    if(currentCategory && currentCategory.vote){
      const votes = currentCategory.vote;
      for(let i = 0; i < maxIndications; i++){

        const name = votes[i]?.voted ? votes[i]?.indicationName ?? '' : '';
        const linkedin = votes[i]?.voted ? votes[i]?.linkedin ?? '' : '';
        const voted = votes[i]?.voted ? true : false;

        setIndicationField((prevState)=>{
          return {
            ...prevState,
            [i]: name
          }
        })
        setLinkedinField((prevState)=>({
          ...prevState,
          [i]: linkedin
        }))
        setChecked((prevState)=>({
          ...prevState,
          [i]: voted
        }))
      }
    }

  }, [currentCategory])

  const handleIndication = async(n:keyof votesObject) => {

    if (checked[n] || currentCategory?.vote[n]?.voted) return

    if (!linkedinField[n] || !indicationField[n]) {
      toast.error('Preencha os dois campos para fazer a indicação')
      return
    }

    if (
      !(
        linkedinField[n]?.includes('https://') ||
        linkedinField[n]?.includes('http://') ||
        linkedinField[n]?.includes('www.')
      )
    ) {
      toast.error(
        'Por gentiliza preencha corretamente o link. Deve iniciar com https:// ou www',
      )
      return
    }

    setChecked((prevState) => ({
      ...prevState,
      [n]: true,
    }));

    if (indicationField[n] && linkedinField[n]) {
      makeIndication(indicationField[n] as string, linkedinField[n] as string, n);
    }

    if (currentCategory && currentCategory.category) {
      toast.success(
        `Sua indicação para a categoria ${currentCategory.category.name} foi salva com sucesso!`,
      )
    }
  }

  useEffect(() => {
    if (!currentStep) return

    if (currentStep?.page !== history.location.pathname) {
      history.push(currentStep?.page)
    }
  }, [currentStep])

  // Use Effect pra trocar a label de acordo com a categoria
  useEffect(() => {
    if (!currentCategory) return
    if (!currentCategory.category?.name) return

    const catPessoas = [
      'Investidor Anjo',
      'Profissional de imprensa',
      'Mentor ou Mentora',
      'Herói ou Heroína',
    ]

    if (catPessoas.includes(currentCategory.category.name)) {
      setLabel('LINKEDIN CORRESPONDENTE')
    } else {
      setLabel('SITE/LINKEDIN CORRESPONDENTE')
    }
  }, [currentCategory])

  if (!currentCategory || !currentCategory.category || !currentCategory.vote)
    return <></>

  return (
    <Container>
      <S.Wrapper>
        <S.WrapperGiveBack>
          <p>Give Back por</p>
          <img src={msLogo} />
        </S.WrapperGiveBack>
        <S.WrapperPage>
          <S.WrapperCategory criterionIsOpen={criterionIsOpen}>
            {/* <S.MaskImage>
              <img src={getImgPath(currentCategory.category.image.path)} />
            </S.MaskImage> */}
            <S.WrapperInfoCategory>
              <S.TitleCategory>{currentCategory.category.name}</S.TitleCategory>
              <S.DescriptionCategory>
                {currentCategory.category.description}
              </S.DescriptionCategory>
            </S.WrapperInfoCategory>
          </S.WrapperCategory>
          <S.WrapperCriterionBtn>
            <S.WrapperAbsCriterionBtn criterionIsOpen={criterionIsOpen}>
              <OutlinedButton
                isGray
                onClick={() => setCriterionIsOpen(!criterionIsOpen)}>
                Critérios para Indicação
              </OutlinedButton>
            </S.WrapperAbsCriterionBtn>
            <S.ModalCriterion criterionIsOpen={criterionIsOpen}>
              <S.XIcon onClick={() => setCriterionIsOpen(!criterionIsOpen)} />
              <S.ModalCriterionHead>
                <p>Critérios da Categoria</p>
              </S.ModalCriterionHead>
              {currentCategory.category.requirements.map((criterion) => {
                return (
                  <S.WrapperCriterion key={criterion.order}>
                    <S.Criterion>{criterion.requirement}</S.Criterion>
                  </S.WrapperCriterion>
                )
              })}
            </S.ModalCriterion>
          </S.WrapperCriterionBtn>

          {
            [...Array(maxIndications)].map((e, i)=>(
              <S.FieldRow key={i}>
                <S.WrapperField>
                <IndicationField
                isChecked={checked[i]}
                disabled={checked[i-1] || i === 0 ? false : true}
                label="NOME COMPLETO DA SUA INDICAÇÃO"
                placeholder="Digite o nome completo aqui"
                value={indicationField[i as keyof votesObject]}
                onChange={(ev) =>
                  setIndicationField({
                    ...indicationField,
                    [i]: ev.target.value,
                  })
                }
                />
                </S.WrapperField>
                <S.WrapperField>
                <IndicationField
                isChecked={checked[i]}
                disabled={checked[i-1] || i === 0 ? false : true}
                label={label}
                placeholder="Cole a URL da empresa ou pessoa aqui"
                value={linkedinField[i as keyof votesObject]}
                onChange={(ev) =>
                  setLinkedinField({
                    ...linkedinField,
                    [i]: ev.target.value,
                  })
                }
                />
                </S.WrapperField>
                <NewButton
                  isChecked={checked[i]}
                  disabled={checked[i-1] || i === 0 ? false : true}
                  onClick={()=>handleIndication(i as keyof votesObject)}
                  className="action">
                  {checked[i] ? <TbCheck /> : <TbArrowBigRightLinesFilled />}
                </NewButton>
              </S.FieldRow>
            ))
          }




          <S.WrapperPagination>
            <S.PaginationButton
              disabled={prevButton.disable}
              onClick={prevButton.onClick}>
              <img src={prevBtn} />
            </S.PaginationButton>

            <S.PaginationButton
              disabled={nextButton.disable}
              onClick={nextButton.onClick}>
              <img src={nextBtn} />
            </S.PaginationButton>
          </S.WrapperPagination>
          <Realization />
        </S.WrapperPage>
      </S.Wrapper>
      <ApresentacaoIndicacoes />
    </Container>
  )
}

export default Indicacao
