import React, {useEffect, useState} from 'react'
import AuthContext from './Context'
import UserService from '../../service/users'
import {User} from '../../models/User'
import {useHistory} from 'react-router-dom'
import {USER_LS} from '../../utils/localStorage/constants'
import {useApp} from '../AppContext/Context'
import {steps} from '../VotationContext/Provider'
import {Step} from '../../models/Step'
import {toast} from 'react-toastify'

interface ApiError {
  status: number;
  data: {
    data: {
      text: string;
    };
  };
}

const AuthProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isLogging, setIsLogging] = useState(false)
  const {setIsLoading} = useApp()
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const userLogged = localStorage.getItem(USER_LS)

    if (userLogged) setUser(JSON.parse(userLogged))
  }, [])

  const userIsLogged = () => {
    return Boolean(localStorage.getItem(USER_LS))
  }

  const logout = async () => {
    localStorage.removeItem(USER_LS)
    setUser(undefined)
    history.push('/login')
  }

  const login = async (email: string, password: string, captcha: boolean) => {
    try {
      setIsLogging(true)

      if (!captcha) {
        setIsLogging(false)
        return toast.error('Por gentileza valide o Captcha', {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }

      const res = await UserService.login({email, password})

      setIsLogging(false)
      setIsLoading(true)

      localStorage.setItem(USER_LS, JSON.stringify(res))
      setUser(res)

      isOpen && setIsOpen(false)

      const needOptIn = await checkOptIn(res)

      if (!needOptIn) {
        setIsLoading(false)
        return history.push('/optin')
      } else {
        const step: Step = steps[
          res.stage.stage as 'Fase 1' | 'Fase 2' | 'Fase 3'
        ] as Step
        return history.push(step.page)
      }
    } catch (err) {
      const error = err as ApiError;
      console.log(error.status)
      setIsLoading(false)
      setIsLogging(false)

      if (error.status === 503) {
        return toast.error(error.data.data.text, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }

      return toast.error('E-mail ou senha incorretos', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const checkOptIn = async (paramUser: User) => {
    return new Promise((resolve) => {
      UserService.getById({
        id: paramUser.id,
        token: paramUser.token,
      }).then((res: User) => {
        if (
          !res.userTerms ||
          (Array.isArray(res.userTerms) && res.userTerms.length === 0) ||
          res.userTerms.filter((term) => term.status).length > 0
        ) {
          return resolve(true)
        }

        return resolve(false)
      })
    })
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userIsLogged,
        login,
        logout,
        isLogging,
        isOpen,
        setIsOpen,
        toggleMenu,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
