import styled from 'styled-components'
import colors from '../../styles/colors'

export const RegisterWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 20px;

  @media (max-width: 992px) {
    min-height: unset;
    padding-top: 50px;
  }
`

export const RegisterForm = styled.form`
  width: 100%;
`

export const TxtInfo = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.15;
  letter-spacing: -0.03em;
  color: ${colors.white.primary};
  margin-bottom: 30px;

  @media (max-width: 600px) {
    font-size: 26px;
    text-align: left;
    line-height: 1.25;
  }
`

export const LimitedDiv = styled.div`
  width: 100%;
  margin: 0 auto;
`

export const WrapperFields = styled('div')<{first?: boolean}>`
  width: 100%;
  margin: 15px auto;
  ${(props) => props.first && 'margin-top: 40px'}
`

export const WrapperActionButton = styled.div`
  margin: 20px 0;
  width: 100%;
  text-align: center;

  button {
    min-width: 70%;
  }
`

export const WrapperRecaptcha = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const RecaptchaError = styled.p`
  color: ${colors.copper.primary};
  margin-top: 10px;
  font-weight: 500;
`

export const WrapperLogin = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px auto 0px;
`

export const WrapperRealization = styled.div`
  margin: 50px auto 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`
