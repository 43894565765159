import React from 'react'
import {useHistory} from 'react-router-dom'

import {Container} from '../../styles/globals'

import * as S from './styles'
import NewButton from '../../components/Buttons/NewButton'
import {useVotation} from '../../context/VotationContext/Context'
import Realization from '../../components/Realization'

const Agradecimento: React.FC = () => {
  const history = useHistory()
  const {currentStep} = useVotation()

  return (
    <>
      <Container>
        <S.Wrapper>
          <S.LimitedDiv>
            <S.TxtHeading>
              Missão <br />
              cumprida!
            </S.TxtHeading>
            <S.TxtInfo>
              Obrigado pela
              <br />
              sua participação.
            </S.TxtInfo>
            <S.Ok>
              <span>OK</span>
            </S.Ok>

            <S.TxtIndications>
              {currentStep && currentStep.order > 1
                ? 'Seus votos'
                : 'Suas indicações'}{' '}
              em todas
              <br /> as categorias
              <br /> foram{' '}
              {currentStep && currentStep.order > 1
                ? 'computados'
                : 'computadas'}
              .
            </S.TxtIndications>
            <S.WrapperBtn>
              <NewButton onClick={() => history.push('/')}>
                Voltar para home
              </NewButton>
            </S.WrapperBtn>
          </S.LimitedDiv>
          <Realization />
        </S.Wrapper>
      </Container>
    </>
  )
}

export default Agradecimento
