import styled from 'styled-components'
import colors from '../../styles/colors'

interface LimitedDivProps {
  first?: boolean
}

export const TxtWarning = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: ${colors.white.primary};
  margin-bottom: 20px;
`

export const TxtInfo = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  color: ${colors.white.primary};
  margin-bottom: 20px;
`

export const LimitedDiv = styled('div')<LimitedDivProps>`
  max-width: 330px;
  width: 100%;
  margin: 10px auto;
  ${(props) => (props.first ? `margin-top: 50px` : ``)};

  .outBtn {
    width: 100%;
  }

  .newBtn {
    min-width: 80%;
    margin: 0 auto;
    display: block;
  }
`

export const SwitchWrapper = styled.div`
  max-width: 100%;
  border: 1px solid ${colors.gray.second};
  border-radius: 10px;
  padding: 15px 10px;
`

export const WrapperRealization = styled.div`
  margin: 50px auto 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`
