import styled from 'styled-components'
import colors from '../../styles/colors'

interface Modal {
  active?: boolean
}

export const ProfileWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 20px;

  @media (max-width: 992px) {
    min-height: unset;
    padding-top: 50px;
  }
`

export const ProfileForm = styled.form`
  width: 100%;
`

export const TxtInfo = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.15;
  letter-spacing: -0.03em;
  color: ${colors.white.primary};
  margin-bottom: 30px;

  @media (max-width: 600px) {
    font-size: 26px;
    text-align: left;
    line-height: 1.25;
  }
`

export const LimitedDiv = styled.div`
  max-width: 434px;
  width: 100%;
  margin: 0 auto;
`

export const LastDiv = styled(LimitedDiv)`
  margin-top: 30px;
  max-width: 350px;
`

export const ErrorSpan = styled.div`
  max-width: 434px;
  width: 100%;
  margin: 0 auto;
  color: ${colors.red.primary};
`

export const WrapperTxtInfo = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0px;
`

export const WrapperFields = styled('div')<{first?: boolean}>`
  max-width: 434px;
  width: 100%;
  margin: 10px auto;
  ${(props) => props.first && 'margin-top: 10px'}
`

export const WrapperActionButton = styled.div`
  margin: 20px auto 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  
  button {
    min-width: 70%;
    mat
  }
`

export const WrapperForgetButton = styled.div`
  display: flex;
  justify-content: center;
`

export const TxtDelete = styled.p`
  text-align: center;
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;
  margin: 20px auto;
  cursor: pointer;
`
export const MenuIconWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 25px;
`
export const ModalDeleteWrapper = styled('div')<Modal>`
  width: ${(props) => (props.active ? '100%' : `0px`)};
  height: ${(props) => (props.active ? '100%' : `0px`)};
  overflow: hidden;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: opacity 0.32s ease;
  position: fixed;
  z-index: 30;
  background: ${colors.black.primary70};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ModalDelete = styled.div`
  width: 450px;
  max-width: 90%;
  height: 240px;
  background: ${colors.black.primary};
  padding: 30px 25px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    height: auto;
  }
`
export const ModalDeleteTitle = styled.p`
  color: ${colors.copper.primary};
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 10px;
  font-size: 18px;
`

export const ModalDeleteText = styled.p`
  color: ${colors.gray.light};
  line-height: 1.5;
  font-size: 18px;
`

export const ModalDeleteBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  button {
    max-width: 45%;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      max-width: unset;

      & + button {
        margin-top: 15px;
      }
    }
  }
`

export const WrapperRealization = styled.div`
  margin: 60px auto 40px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px auto 30px;
  }

  & img {
    margin-top: 10px;
    height: 35px;
    width: auto;
    &.blanko {
      height: 22px;
      padding-bottom: 6px;
    }

    @media (max-width: 600px) {
      height: 26px;
      &.blanko {
        height: 18px;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 13px 0 11px;
      color: #fff;
    }
  }
`

export const TxtRealization = styled.span`
  text-align: left;
  font-size: 11px;
  letter-spacing: 4.2px;
  color: ${colors.gray.light};
  text-transform: uppercase;

  @media (max-width: 600px) {
    font-size: 9px;
  }
`
