import React from 'react'
import {Switch, Route, useHistory} from 'react-router-dom'
import routes from './routes'
import PrivateRoute from '../components/PrivateRoute'
import useGoogleAnalytics from '../utils/analytics'

const Routes: React.FC = () => {
  const history = useHistory()
  useGoogleAnalytics()

  return (
    <Switch>
      {routes.map(({Component, ...route}) =>
        route.private ? (
          <PrivateRoute key={route.name} path={route.route}>
            <Component history={history} />
          </PrivateRoute>
        ) : (
          <Route
            key={route.name}
            exact
            path={route.route}
            component={Component}
          />
        ),
      )}
    </Switch>
  )
}

export default Routes
