import React, { Dispatch, SetStateAction, useContext } from "react";

interface AppContextType {
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const AppContext = React.createContext<AppContextType>({
  isLoading: false,
  setIsLoading: () => undefined
});

export default AppContext;
export const useApp = () => useContext(AppContext);