import React, {useState} from 'react'
import {Col, Container, Row} from 'react-grid-system'
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {toast} from 'react-toastify'
import * as S from './styles'
import PasswordField from '../../components/Inputs/PasswordField'
import NewButton from '../../components/Buttons/NewButton'
import Realization from '../../components/Realization'
import {useHistory, useLocation} from 'react-router'

import LinkButton from '../../components/Buttons/LinkButton'

import userService from '../../service/users'

interface IFormInputs {
  password: string
  confirmPassword: string | undefined
}

const schema = yup.object({
  password: yup
    .string()
    .required('Este campo é obrigatório')
    .min(8, 'Digite pelo menos 8 caracteres')
    .max(20, 'Digite pelo no máximo 20 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).{8,256}$/,
      'Sua nova senha deve conter pelo menos 8 caracteres, contendo um número, um caractere especial, uma letra maiúscula e uma minúscula.',
    ),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'A confirmação deve ser igual a senha.',
    ),
})

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const RecoveryPassword: React.FC = () => {
  const query = useQuery()

  const code = query.get('code')

  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  })

  if (!code) {
    toast.error(
      'Código da requisição inválido. Verifique o link no seu e-mail!',
      {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      },
    )
    history.push('/')
  }

  const onSubmit = async (data: IFormInputs) => {
    setLoading(true)

    await userService
      .changePassword({
        ...data,
        code,
      })
      .then(() => {
        setLoading(false)
        toast.success(
          'Sua senha foi alterada com sucesso. Realize o login para acessar!',
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        )
        history.push('/login')
      })
      .catch((error) => {
        setLoading(false)

        toast.error(error.data.modal, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  return (
    <>
      <Container component="form" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <S.LimitedDiv>
              <S.TxtInfo>
                Digite sua nova senha. <br />
                Deve conter pelo menos oito caracteres com uma letra maiúscula,
                uma minúscula e um número.
              </S.TxtInfo>
            </S.LimitedDiv>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({field: {onChange, onBlur, value, ref}}) => (
                <S.WrapperFields>
                  <PasswordField
                    label="Digite sua senha"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    error={!!errors && !!errors.password}
                    errorMessage={
                      !!errors && !!errors.password
                        ? errors.password.message
                        : ''
                    }
                  />
                </S.WrapperFields>
              )}
            />
            <Controller
              control={control}
              name="confirmPassword"
              defaultValue=""
              render={({field: {onChange, onBlur, value, ref}}) => (
                <S.WrapperFields>
                  <PasswordField
                    label="Confirme sua senha"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    error={!!errors && !!errors.confirmPassword}
                    errorMessage={
                      !!errors && !!errors.confirmPassword
                        ? errors.confirmPassword.message
                        : ''
                    }
                  />
                </S.WrapperFields>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <S.WrapperActionButton>
              <NewButton disabled={loading} isLoading={loading} type="submit">
                Trocar senha
              </NewButton>
            </S.WrapperActionButton>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <S.WrapperForgetButton>
              <LinkButton onClick={() => history.push('/')}>
                VOLTAR PARA HOME
              </LinkButton>
            </S.WrapperForgetButton>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Realization />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default RecoveryPassword
